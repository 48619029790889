import { useState } from "react";
import { ghosts } from "../../Data/Ghosts";
import GhostModal from "../Modal/GhostModal";
import Modal from "../Modal/Modal";

const Ghosts = () => {
  const [modalActive, setModalActive] = useState(null);
  const [index, setIndex] = useState(0);

  // const chunks = ghosts.reduce((all, one, i) => {
  //   const ch = Math.floor(i / 2);
  //   all[ch] = [].concat((all[ch] || []), one);
  //   return all
  // }, []);

  const OFFSET = 4; // SHould be dynamic

  const closeModal = () => {
    setModalActive(null);
  }

  const openModal = (index) => {
    if (ghosts[index].name) {
      setModalActive(index);
    }
  }

  const handleScroll = (change) => {
    const el = document.getElementById('ghost-scroll');
    let adding = el.clientWidth * change;
    let target = adding + el.scrollLeft;

    el.scrollLeft = target;

  };

  // console.log("GHOST COUNT: ", ghosts.length);


  return <section id="Ghosts" className="relative z-20">
    <div className="mt-[-4%] z-20 relative">
      <img alt="" width="1920" height="96" loading="lazy" src={require('../../Assets/Ghosts/header.png').default} />
    </div>
    <div className="relative pt-[1px] !bg-contain mt-[-1%]" style={{ background: `url(${require('../../Assets/Ghosts/bg.jpg').default}) no-repeat center top #383838` }}>
      <h1 className="mt-[2vw] flex justify-center items-center text-white text-[4vw] font-extrabold tracking-wider uppercase" style={{
        textShadow: `0px 2px 6px #333`,
      }}>
        Ghosts
      </h1>

      <div className="w-full">
        <div id="ghost-scroll" style={{ "--ghosts-columns-sm": Math.ceil(ghosts.length / 3), "--ghosts-columns-md": Math.ceil(ghosts.length / 2) }}  className="grid grid-rows-3 grid-cols-[repeat(var(--ghosts-columns-sm),33%)] md:grid-rows-2 md:grid-cols-[repeat(var(--ghosts-columns-md),_20%)] grid-flow-col relative z-20 overflow-x-auto overflow-y-hidden sm:overflow-hidden scroll-smooth">
          {ghosts.map((g, idx) => (
            <div key={`ghost_${idx}`} className={`flex flex-col items-center ${g.name ? 'justify-center' : 'justify-start'} gap-2 p-2 hover:scale-110 transition-transform ${g.name ? 'cursor-pointer' : ''}`} onClick={() => openModal((idx))}>
              <picture>
                <source srcSet={g.image.webp} type="image/webp" />
                <source srcSet={g.image.png} type="image/png" />
                <img alt={g.name ?? '???'} loading="lazy" width={g.image.width} height={g.image.height} src={g.image_png} />
              </picture>
              <div className="flex items-center justify-center text-white text-sm sm:text-base md:text-base lg:text-2xl xl:text-3xl uppercase font-bold">{g.name}</div>
            </div>
          ))}
        </div>

      </div>
      <div className="w-full relative z-20">
        <div className="absolute flex justify-center items-center w-full gap-1 translate-y-[-25%]">
          <img alt="prev" loading="lazy" width="184" height="184" src={require("../../Assets/Shared/Arrows/purple.png").default} onClick={() => handleScroll(-1)} className="w-[10%] scale-x-[-1] cursor-pointer z-50" />
          <img alt="next" loading="lazy" width="184" height="184" src={require("../../Assets/Shared/Arrows/purple.png").default} onClick={() => handleScroll(1)} className="w-[10%] cursor-pointer  z-50" />
        </div>
      </div>
    </div>

    <div className="h-0 relative z-10 translate-y-0">
      <img alt="" loading="lazy" src={require('../../Assets/Ghosts/footer.png').default} className="absolute top-0 mt-[-8%]" />
    </div>

    {modalActive !== null ? <Modal onClose={closeModal}>
      <GhostModal index={modalActive} />
    </Modal> : null}
  </section>;
}

export default Ghosts;