

let fakeGhosts = [];

for (let i = 0; i <= 14; i++) {
  fakeGhosts.push({
    "image": {
      "png": require(`../Assets/Ghosts/lineup/temp/SG${i}.png`).default,
      "webp": require(`../Assets/Ghosts/lineup/temp/SG${i}.webp`).default,
      "width": 383,
      "height": 291
    },
  });
}

export const ghosts = [
  {
    "name": "Bananice",
    "image": {
      "png": require("../Assets/Ghosts/lineup/101_bananice.png").default,
      "webp": require("../Assets/Ghosts/lineup/101_bananice.webp").default,
      "width": 383,
      "height": 291
    },
    "card": require("../Assets/Ghosts/Cards/FRACTAL Bananice.jpg").default,
    "infos": {
      "kawaii name": "Freezofear",
      "kaiju name": "Bananice",
      "level": "6",
      "family": "Fractal",
      "types": "Ice / Merger",
      "nest": "An old abandoned fridge",
      "receptacle": "Banana flavored ice-creams",
      "age": "Horror",
      "rarity": "Uncommon",
      "deplacement": "Jumping, sliding and climbing.",
      "Weakness": "To defeat Bananice, you\u2019ll need to expose it to a strong enough heat source\u2026 Such as the light of the sun, reflected through a giant magnifying glass!",
      "": "",
      "attacks": [
        {
          "number": 1,
          "name": "ICE-SCREAM",
          "text": "In Kawa\u00ef form, FREEZOFEAR can freeze people over just by going trough them. Creepy\u2026 And cold! Luckily, a strong enough heat source is enough to revert the ghost\u2019s effect and brings you back to room temperature. In its booster mode, BANANICE also becomes able to freeze everything by howling out an icy blast of air, that freezes entire areas!"
        },
        {
          "number": 2,
          "name": "BANANA-SCOOPS",
          "text": "In his booster mode, Bananice can shoots ice-cream scoops projectile from its hand. While those can\u2019t turn you into ice, they\u2019re still fairly cold\u2026 And delicious !"
        },
        {
          "number": 3,
          "name": "BANANICE-AGE",
          "text": "In Booster mode, once capped on boo energy, BANANICE can shoots an icy beam from its head that will transform the sky into a potent ice-storm, quickly lowering the temperature and sending its surrounding into a new ice-age !"
        }
      ],
      "defenses": [
        {
          "number": 1,
          "name": "ICE-CAPADE",
          "text": "In Booster mode, BANANICE leaves a sheet of ice under its feets, wherever it goes. This allows it to increase its speed and to dodge incoming attack\u2026 By sliding on ice!"
        },
        {
          "number": 2,
          "name": "BOO ENERG-ICED",
          "text": "Where most Ghosts have to feed on fear to power up their attacks, FREEZOFEAR/BANANICE is able to convert cryogenized protein into Boo energy. This allows it to power up\u2026 Just by eating ice-creams!"
        }
      ]
    }
  },
  {
    "name": "Glougloux",
    "image": {
      "png": require("../Assets/Ghosts/lineup/102_glougloux.png").default,
      "webp": require("../Assets/Ghosts/lineup/102_glougloux.webp").default,
      "width": 383,
      "height": 291
    },
    "card": require("../Assets/Ghosts/Cards/MOLECULAR Glougloux.jpg").default,
    "infos": {
      "kawaii name": "Freezofear",
      "kaiju name": "Glougloux",
      "level": "8",
      "family": "Molecular",
      "types": "Multiplier / Teleporter / Shrieker",
      "nest": "Stored in Boocap",
      "receptacle": "None",
      "age": "Terror",
      "rarity": "Rare",
      "deplacement": "Teleportation (in a spiral pattern)",
      "Weakness": "Despite his high level, GLOUGLOUX is a very frail Ghost, that could be easily boocaped even in its booster mode\u2026 Provided you can actually reach it as it keeps teleporting away and drowning you under a sea of cloned eggs! To catch it, you\u2019ll need to discover the spiral of its teleportation\u2019s pattern, in order to guess where it\u2019ll appear next.",
      "": "",
      "attacks": [
        {
          "number": 1,
          "name": "MULTEGGPLICATION",
          "text": "In booster mode, GLOUGLOUX can multiply its eggs exponentially by cloning them. One egg become two eggs, become four eggs, become eight eggs\u2026 Become a crushing wave of thousands of ghastly eggs!"
        },
        {
          "number": 2,
          "name": "STUNNING SCREAM",
          "text": "In booster mode, GLOUGLOUX can let out a screeching scream that\u2019ll temporally stun anyone hearing it."
        },
        {
          "number": 3,
          "name": "SPECTRAL SCREAM",
          "text": "When capped on boo energy, GLOUGLOUX can let out an even more terrifying scream that\u2019ll not only temporally stuns everyone around\u2026 But also hatches all of its eggs into cloned version of GLOUGLOUX!"
        },
        {
          "number": 4,
          "name": "EGG SWARM",
          "text": "In booster mode, GLOUGLOUX can order its swarm of eggs and clones around to use them as its personal army, quickly overwhelming its foe under their numbers."
        }
      ],
      "defenses": [
        {
          "number": 1,
          "name": "TELEGGPORTATION",
          "text": "When in danger, GLOUGLOUX will teleport away in a spiral pattern, leaving an egg behind it. The eggs stay dormant until GLOUGLOUX hatches them with his SPECTRAL SCREAM."
        },
        {
          "number": 2,
          "name": "TAKE SHELL-TER",
          "text": "When scared GLOUGLOUX can retreat inside its own egg shell\u2026 Though this doesn\u2019t really provide it with any actual protection at all!"
        }
      ]
    }
  },
  {
    "name": "Zip-zap",
    "image": {
      "png": require("../Assets/Ghosts/lineup/103_zip-zap.png").default,
      "webp": require("../Assets/Ghosts/lineup/103_zip-zap.webp").default,
      "width": 383,
      "height": 291
    },
    "card": require("../Assets/Ghosts/Cards/SPECTRAL ZIP-ZAP.jpg").default,
    "infos": {
      "kawaii name": "Freezofear",
      "kaiju name": "Zip Zap",
      "level": "4",
      "family": "Spectral",
      "types": "Electric / Hypnotic",
      "nest": "An old, broken TV screen",
      "receptacle": "None",
      "age": "Dread",
      "rarity": "Uncommon",
      "deplacement": "Floating and swimming in the air like an eel",
      "Weakness": "To defeat ZIP-ZAP, you\u2019ll need to avoid looking at it in the eye or at any screen it may have haunted, as doing so will get you hypnotized! Best way to achieve that? Fight it blinded!",
      "": "",
      "attacks": [
        {
          "number": 1,
          "name": "HYPNO FLASH",
          "text": "In its Kawa\u00ef mode ZIP-ZAP can hypnotize anyone looking at a screen he\u2019s haunting! And in booster mode, things gets worse as its eyes themselves turn into screens\u2026 Making it able to hypnotize anyone with just a look!"
        },
        {
          "number": 2,
          "name": "ZAPING BOLT",
          "text": "In its Booster mode, ZIP-ZAP can shoots electric bolt from its head fin, like an electric eel. Beware its projectile, if you don\u2019t\u2019 wanna get fry!"
        }
      ],
      "defenses": [
        {
          "number": 1,
          "name": "ZAPING-IN / ZAPING-OUT",
          "text": "In Kawa\u00ef form, ZIP-ZAP can jump in and out of any screens he gets in touch with in order to haunt them\u2026 Becoming impossible to attack while it\u2019s inside one!"
        }
      ]
    }
  },
  {
    "name": "Trashotic",
    "image": {
      "png": require("../Assets/Ghosts/lineup/104_trashotic.png").default,
      "webp": require("../Assets/Ghosts/lineup/104_trashotic.webp").default,
      "width": 383,
      "height": 291
    },
    "card": require("../Assets/Ghosts/Cards/VISCOUS Trashotic.jpg").default,
    "infos": {
      "kawaii name": "Spong",
      "kaiju name": "Trashotic",
      "level": "4",
      "family": "Viscous",
      "types": "Toxic / Merger",
      "nest": "A dirty trashcan",
      "receptacle": "A dirtier garbage can",
      "age": "Dread",
      "rarity": "Uncommon",
      "deplacement": "Levitating in Kawa\u00ef form. Walking in booster mode.",
      "Weakness": "TRASHOTIC loves the smell of garbage and hates every other smells. Thus the best way to defeat it is to cover its own odor with an ever more powerful one, such as a super strong rose-scented perfume!",
      "": "",
      "attacks": [
        {
          "number": 1,
          "name": "SMELLY BREATH",
          "text": "In both their Kawa\u00ef and Boosted mode, SPONG and TRASHOTIC can use their awful smelling breath as a weapon that is sure to knock-out your sense of smell. Beware : the toxic fumes from their breaths can linger long after they\u2019re gone!"
        },
        {
          "number": 2,
          "name": "GARBAGE BALL",
          "text": "In its Booster mode, TRASHOTIC can shoot dirty balls of garbage goo from its hands, that are not only super-smelly\u2026 But also super-sticky!"
        }
      ],
      "defenses": [
        {
          "number": 1,
          "name": "SMELLY AURA",
          "text": "Ever smelled a bag of garbage filled we dirty baby diapers? SPONG/TRASHOTIC smells like a billion of them, compacted together into one single ghastly pack of trash. It\u2019s not foul smelling\u2026 It\u2019s worse! And it creates a smelly aura around it that is sure to disturb anyone coming close to it."
        }
      ]
    }
  },
  {
    "name": "Mikroo",
    "image": {
      "png": require("../Assets/Ghosts/lineup/106_mikroo.png").default,
      "webp": require("../Assets/Ghosts/lineup/106_mikroo.webp").default,
      "width": 383,
      "height": 291
    },
    "card": require("../Assets/Ghosts/Cards/MOLECULAR MIKROO.jpg").default,
    "infos": {
      "kawaii name": "Spong",
      "kaiju name": "Mikroo",
      "level": "2",
      "family": "Molecular",
      "types": "Shrinker",
      "nest": "A dusty antique",
      "receptacle": "None",
      "age": "Fright",
      "rarity": "Uncommon",
      "deplacement": "Levitation",
      "Weakness": "MIKROO is so fearful that defeating it only requires being able to scare it off\u2026 Maybe by making yourself appear MUCH bigger than it is?",
      "": "",
      "attacks": [
        {
          "number": 1,
          "name": "TINY TOUCH",
          "text": "The cowardly ghost MIKROO can miniaturize anyone it touches or goes through, both in its Kawa\u00ef and Booster mode. Take care not to get in contact with it... Or you'll get to experience the world through the eyes of a mouse-sized human! Being a cowardly ghost who\u2019s afraid of everything bigger than it is, MIKROO is also terrified of other ghosts. And so, its TINY TOUCH has the power to drain boo energy, in order to stop its enemy from using their powers against it."
        }
      ],
      "defenses": [
        {
          "number": 1,
          "name": "DUST CLOUD",
          "text": "To evade its enemy (and everything looking bigger than it) MIKROO can spit out a massive dust cloud that will gets everyone blinded and coughing\u2026 Giving it time to run away!"
        }
      ]
    }
  },
  {
    "name": "Raijin",
    "image": {
      "png": require("../Assets/Ghosts/lineup/107_raijin.png").default,
      "webp": require("../Assets/Ghosts/lineup/107_raijin.webp").default,
      "width": 383,
      "height": 291
    },
    "card": require("../Assets/Ghosts/Cards/SPECTRAL RAIJIN.jpg").default,
    "infos": {
      "kawaii name": "Spong",
      "kaiju name": "Raijin",
      "level": "6",
      "family": "Spectral",
      "types": "Electric / Cloud",
      "nest": "An old electric generator",
      "receptacle": "None",
      "age": "Horror",
      "rarity": "Very rare",
      "deplacement": "Levitation. Though its rather slow as a cloud, it can move ten times faster once it\u2019s charged with electricity!",
      "Weakness": "As dangerous as it is once charged with electricity, RA\u00cfJIN is unable to use its attack without it\u2026 Making draining it out of his juice a winning strategy!",
      "": "",
      "attacks": [
        {
          "number": 1,
          "name": "SPOOK-CIRCUIT",
          "text": "In its Kawa\u00ef form, RA\u00cfJIN can overload and short-circuit any electric appliance, just by going through it. Causing chaos and mayhem as tech get disabled in a fountain of sparks!"
        },
        {
          "number": 2,
          "name": "ELEKSHOCK",
          "text": "In its Booster mode, RA\u00cfJIN can shoot thunderbolt from its antennas to zap its foes with. Upon impact, the target feels a shock go down their entire body, numbing them\u2026 And will see a portion of their boo energy drained!"
        },
        {
          "number": 3,
          "name": "RAIJIN STORM (unused in episode)",
          "text": "In booster mode, once capped on Boo energy, RA\u00cfJIN can unleash this powerful attack. Allowing it to either:  \nA - Pump the energy back into the grid to use SPOOK-CIRCUIT on every appliance (lamps, hairdryers, TVs, ovens, etc.) connected to it.\nB \u2013 Shoot a super powerful thunderbolt that break into smaller bolts to reach several targets at once or converge on a single one. The electric shock is EXTREMLY powerful!"
        }
      ],
      "defenses": [
        {
          "number": 1,
          "name": "RAIJIN CLOUD (unused in episode)",
          "text": "In booster mode, RA\u00cfJIN can surrounds itself with a cloud to avoid being seen and to protect itself. The ghost can thus disappear by melting into the sky without attracting attention. Even if something (a helicopter or a plane for instance) would enter the cloud zone, they\u2019d come out several seconds later, none the wiser."
        },
        {
          "number": 2,
          "name": "CHARGING UP",
          "text": "Where most Ghosts have to feed on fear to power up their attacks, RA\u00cfJIN is also able to convert electricity into Boo energy. This allows it to power itself up, by connecting its body to a powerful electric current."
        }
      ]
    }
  },
  {
    "name": "Pharaok",
    "image": {
      "png": require("../Assets/Ghosts/lineup/108_pharaok.png").default,
      "webp": require("../Assets/Ghosts/lineup/108_pharaok.webp").default,
      "width": 383,
      "height": 291
    },
    "card": require("../Assets/Ghosts/Cards/STRANGE PHARAOK.jpg").default,
    "infos": {
      "kawaii name": "Spong",
      "kaiju name": "Pharaok",
      "level": "7",
      "family": "Strange",
      "types": "Summoner / Sun / Rock",
      "nest": "Pharaoh\u2019s Mask",
      "receptacle": "None",
      "age": "Panic",
      "rarity": "Unique",
      "deplacement": "Levitation",
      "Weakness": "Since PHARAOK\u2019s powers come from its Mummy Army ability, they must first be turned back to their human self in order to deprive the Pharaoh of its powers!",
      "": "",
      "attacks": [
        {
          "number": 1,
          "name": "MUMIFICATION RAY",
          "text": "In Kawa\u00ef form, PHARAOK can shoots laser from its crown, turning anyone it hits into a shambling mummy! As it gets into booster mode, it\u2019ll grow two arms that can shoot Mumification Rays as well\u2026 Making it a triple threat!"
        },
        {
          "number": 2,
          "name": "DESERT HEAT AURA",
          "text": "In both Kawa\u00ef and Booster mode, PHARAOK passively exudes a paranormal heat so strong it can turn entire cities into deserts with enough time! The temperature emanating from its aura is so strong, it can even overwhelms other Ghosts, draining them of their Boo energy."
        },
        {
          "number": 3,
          "name": "THE GREAT PYRAMID",
          "text": "Once capped on Boo energy, PHARAOK can use its ultimate attack to convoke a Ghastly Pyramid. Anyone caught inside of it will experiences tremendous heat, as temperature quickly rises up, accelerating the process of turning the place into a desert from a couple of days\u2026 To mere minutes!"
        }
      ],
      "defenses": [
        {
          "number": 1,
          "name": "MUMMY ARMY",
          "text": "In booster mode, the mummies created by PHARAOK provide him with Boo energy, making him extremely resilient while they\u2019re around! Fortunately, hitting the mummies with any sort of Boo attack transforms them back to their old human self, negating PHARAOK\u2019s advantage."
        },
        {
          "number": 2,
          "name": "UNKNOWN",
          "text": "(Not used in episode)"
        }
      ]
    }
  },
  {
    "name": "Mizuo",
    "image": {
      "png": require("../Assets/Ghosts/lineup/109_mizuo.png").default,
      "webp": require("../Assets/Ghosts/lineup/109_mizuo.webp").default,
      "width": 383,
      "height": 291
    },
    "card": require("../Assets/Ghosts/Cards/FLEXIBLE MIZUO.jpg").default,
    "infos": {
      "kawaii name": "Droplet",
      "kaiju name": "Mizuo",
      "level": "7",
      "family": "Flexible",
      "types": "Water / Multiplier / Merger",
      "nest": "An old water flask",
      "receptacle": "Watertanks",
      "age": "Panic",
      "rarity": "Uncommon",
      "deplacement": "Levitating in Kawa\u00ef mode. Walking and jumping on the water tank\u2019s four legs in Booster mode.",
      "Weakness": "As a Multiplier Ghost, Mizuo relies on the size of its pack to defeat its foes. But the fact that the whole pack disappears when the Alpha goes down means it isn\u2019t necessary to defeat them all\u2026 Only to clog the plumbing of the original one!",
      "": "",
      "attacks": [
        {
          "number": 1,
          "name": "UNKNOWN (unused in episode)",
          "text": "DROPLET\u2019s Kawa\u00ef mode power is still unknown to this day."
        },
        {
          "number": 2,
          "name": "AQUA BLAST",
          "text": "In its Booster mode, MIZUO can use its arm like a water canon, shooting powerful blast of water that\u2019ll knock anyone away! And while the rate of fire of a single isolated MIZUO may be too slow to reliably hit a moving target\u2026 The unending barrage coming from a full pack is sure to eventually connect at some point!"
        },
        {
          "number": 3,
          "name": "ALPHA ROAR",
          "text": "In its Booster mode, MIZUO can let out a powerful roar that calls all the members of its pack to come back to the Alpha\u2019s position. This roar is so strong, that they can hear it no matter where they are."
        }
      ],
      "defenses": [
        {
          "number": 1,
          "name": "AQUA JET (unused in episode)",
          "text": "While MIZUO is unable to levitate in its Booster mode; it can use its arms as a jetpack by shooting powerful streams of water downward. The only disadvantage of this defensive power is that it locks MIZUO\u2019s water canon while using it, preventing it to use its AQUA BLAST in the meantime."
        },
        {
          "number": 2,
          "name": "TENTACULAR PLUMBING",
          "text": "In its booster mode, MIZUO is capable of haunting any water tubes, water tanks and other plumbing utilities connected to the same water circuit as it is\u2026 Effectively allowing the Alpha specimen to multiply itself to form a redoubtable pack of identical ghosts!"
        }
      ]
    }
  },
  {
    "name": "Maastar",
    "image": {
      "png": require("../Assets/Ghosts/lineup/112_maastar.png").default,
      "webp": require("../Assets/Ghosts/lineup/112_maastar.webp").default,
      "width": 383,
      "height": 291
    },
    "card": require("../Assets/Ghosts/Cards/SPECTRAL MAASTAR.jpg").default,
    "infos": {
      "kawaii name": "Droplet",
      "kaiju name": "Maastar",
      "level": "7",
      "family": "Spectral",
      "types": "Metal / Musical / Hypnotic",
      "nest": "A ukulele",
      "receptacle": "None",
      "age": "Panic",
      "rarity": "Rare",
      "deplacement": "Levitating\u2026 In music!",
      "Weakness": "As Mastaar feeds upon the adoration of its mesmerized audience, you\u2019ll need to break the hypnotic spell it keeps them under\u2026 By drowning its music with your own, in a true solo battle. Rock on!",
      "": "",
      "attacks": [
        {
          "number": 1,
          "name": "SING-A-BOO",
          "text": "In Kawa\u00ef form, MASTAAR\u2019s singing can release shot-ranged mesmerizing waves that will hypnotize anyone listening to it, turning its victims into its absolute fanboys and fangirls of MASTAAR!"
        },
        {
          "number": 2,
          "name": "BOOTALIKA",
          "text": "As it gets into booster mode, MASTAAR can then expand its repertoire to music as well, as he becomes able to mesmerized entire crowds at long-range, just by performing for them. Such a rockstar!"
        },
        {
          "number": 3,
          "name": "SPOOKLIGHTS",
          "text": "In booster mode, once capped on Boo energy, MASTAAR can use the spotlights on its back to shoot immobilizing rays, that allows him to turn anyone trying to overshadows it into a statue! So covering your ears won\u2019t be nearly enough to defeat it!"
        }
      ],
      "defenses": [
        {
          "number": 1,
          "name": "MUSICAL INVOCATION",
          "text": "In booster mode, MASTAAR can convoke musical instrument made out pure concentrated Boo energy. Those then act as amplifiers for its other powers, increasing their range and boosting their effects (as well as Mastaar\u2019s general loudness!)."
        },
        {
          "number": 2,
          "name": "BOO STAR",
          "text": "Where most Ghosts have to feed on fear to power up their attacks, MASTAAR is also able to feed on the adoration of its mesmerized \u201cfans\u201d.  The more they cheers for it\u2026 The more Boo energy it gets!"
        }
      ]
    }
  },
  {
    "name": "Krikkrok",
    "image": {
      "png": require("../Assets/Ghosts/lineup/114_krikkrok.png").default,
      "webp": require("../Assets/Ghosts/lineup/114_krikkrok.webp").default,
      "width": 383,
      "height": 291
    },
    "card": require("../Assets/Ghosts/Cards/SPECTRAL KRIK KROK.jpg").default,
    "infos": {
      "kawaii name": "Gmagia",
      "kaiju name": "Krik Krok",
      "level": "4",
      "family": "Spectral",
      "types": "Plant / Sun",
      "nest": "A plant pot",
      "receptacle": "None",
      "age": "Dread",
      "rarity": "Uncommon",
      "deplacement": "Levitation",
      "Weakness": "KRIK KROK\u2019s powers all originates from the seed on its head. As such, in order to defeat it, you\u2019ll only need to reach it with your attacks\u2026  While preventing KRIK KROK from shielding it with its leaves!",
      "": "",
      "attacks": [
        {
          "number": 1,
          "name": "SPECTRAL BRAMBLES",
          "text": "In both its Kawa\u00ef and Booster mode, KRIK KROK can shoot pollen-like projectiles from its seed, that very quickly sprout into giant brambles as soon as they hit something solid ! Those brambles are so invasive that they\u2019ll not only entrap people\u2026 They\u2019ll quickly turn whatever area KRIK KROK is attacking into a real thorny jungle!"
        },
        {
          "number": 2,
          "name": "SPINNING LEAVES",
          "text": "In booster mode, KRIK KROK can quickly spin on itself, to use the leaves on its head like blades from a dangerous lawnmower !"
        }
      ],
      "defenses": [
        {
          "number": 1,
          "name": "LEAFY SHIELD",
          "text": "In booster mode, KRIK KROK can retract the leaves on its head to shield its seed, rendering it impervious to any attack. Fortunately, doing so also prevents it to use its other abilities in the mean time."
        }
      ]
    }
  },
  {
    "name": "Sharkoak",
    "image": {
      "png": require("../Assets/Ghosts/lineup/115_sharkoak.png").default,
      "webp": require("../Assets/Ghosts/lineup/115_sharkoak.webp").default,
      "width": 383,
      "height": 291
    },
    "card": require("../Assets/Ghosts/Cards/SPECTRAL SHARKOAK.jpg").default,
    "infos": {
      "kawaii name": "Gmagia",
      "kaiju name": "Sharkoak",
      "level": "7",
      "family": "Spectral",
      "types": "Stinky / Glutton / Water",
      "nest": "Shark tooth necklace",
      "receptacle": "None",
      "age": "Panic",
      "rarity": "Rare",
      "deplacement": "\u201cSwim\u201d around by levitating. Likes to swim into the ground, with only its backfin poking out menacingly.",
      "Weakness": "As a redoubtable stinky type ghost, Sharkoak has everything that smells good in horror and is sure to get stunned by this kind of scent ! Make sure to use that to your advantage when facing this dangerous glutton.",
      "": "",
      "attacks": [
        {
          "number": 1,
          "name": "BREATH OF NIGHTMARE",
          "text": "In both its Kawa\u00ef and Booster mode, SHARKOAK exhales a foul smelling cloud of stink that is sure to make you balk in disgust. And if he manages to go trough you, that stink will stick to you forever !"
        },
        {
          "number": 2,
          "name": "ANCHOR THROW",
          "text": "In its booster mode, SHARKOAK is able to throw out the two anchors it has at the end of its fins at phenomenal speed. This very powerful and fast attack often allows him to punish an opponent who is too slow, or to attack by surprise by leaping out of a building while attacking."
        },
        {
          "number": 3,
          "name": "JAWS FROM HELL (unused in episode)",
          "text": "SHARKOAK is a huge glutton and attack by opening up its huge jaws to literally gulp down its target, turning it into a pile of green slime that spews an unbearable smell and smoke. The bigger the ghost gets, the bigger the things it can swallow. It would be a shame if the Statue of Liberty were turned into a pile of stinky slime, wouldn\u2019t it?"
        }
      ],
      "defenses": [
        {
          "number": 1,
          "name": "STINKY CLOUD",
          "text": "SHARKOAK can shoot out foul-smelling gas from its fins, shrouding itself in a big stinky cloud to evade its enemy."
        },
        {
          "number": 2,
          "name": "ANCHOR TORNADO (unused in episode)",
          "text": "To protect itself, SHARKOAK plants its anchor and moves in circles around it increasingly fast. An greenish tornado of Boo energy appears around it, rising to several dozen feet high and pushing opponents away."
        }
      ]
    }
  },
  {
    "name": "Arakgum",
    "image": {
      "png": require("../Assets/Ghosts/lineup/116_arakgum.png").default,
      "webp": require("../Assets/Ghosts/lineup/116_arakgum.webp").default,
      "width": 383,
      "height": 291
    },
    "card": require("../Assets/Ghosts/Cards/SPECTRAL ARAKGUM.jpg").default,
    "infos": {
      "kawaii name": "Arak",
      "kaiju name": "Arakgum",
      "level": "5",
      "family": "Spectral",
      "types": "Collector / Merger",
      "nest": "Spider\u2019s web",
      "receptacle": "A piece of chewing-gum",
      "age": "Awe",
      "rarity": "Rare",
      "deplacement": "Flight and levitation in both modes.",
      "Weakness": "As ARAK GUM\u2019s webs are made of gum, they\u2019re sensible to temperature change. Freezing or melting them will render the ghost harmless, making its capture easy.",
      "": "",
      "attacks": [
        {
          "number": 1,
          "name": "GUMMY WEB",
          "text": "In its Booster mode, ARAKGUM can weave out threads of spider web, made out of chewing-gum, to entrap its prey and build impressive labyrinths of gummy webs. And beware : those sticky, resilient and strawberry-flavored webs are practically impossible to escape ! Nothing short of freezing temperatures will be able to break you out of it."
        },
        {
          "number": 2,
          "name": "GUMMY BLAST",
          "text": "In its Booster mode, ARAKGUM can shoot chewing gum projectiles that\u2019ll get their target completely stuck. Keep you heads down, lest you get some in your hair !"
        }
      ],
      "defenses": [
        {
          "number": 1,
          "name": "WEB ALARM",
          "text": "In its booster mode, ARAK GUM\u2019s gummy webs act as tripwires, alerting it to the presence of foes on its territory when someone touches one."
        },
        {
          "number": 2,
          "name": "GUMMY SHIELD (unused in episode)",
          "text": "In its booster mode, ARAK GUM can blow a giant bubblegum from its mouth in order to shield itself from incoming attack."
        }
      ]
    }
  },
  {
    "name": "Xhypno",
    "image": {
      "png": require("../Assets/Ghosts/lineup/117_xhypno.png").default,
      "webp": require("../Assets/Ghosts/lineup/117_xhypno.webp").default,
      "width": 383,
      "height": 291
    },
    "card": require("../Assets/Ghosts/Cards/SPECTRAL XHYPNO.jpg").default,
    "infos": {
      "kawaii name": "Arak",
      "kaiju name": "Xhypno",
      "level": "9",
      "family": "Spectral",
      "types": "Hypnotic / Metal / Plant / Wind",
      "nest": "Old butterfly brooch",
      "receptacle": "None",
      "age": "Abomination",
      "rarity": "Rare",
      "deplacement": "Levitation in kawa\u00ef mode. Flight in booster mode.",
      "Weakness": "As resistant as they are, XHYPNO\u2019s crystal are very sensible to sound. Making very high-pitched notes the best way to shatter them and capture this extremely dangerous specimen!",
      "": "",
      "attacks": [
        {
          "number": 1,
          "name": "HYPNO CRYSTAL",
          "text": "In its Kawa\u00ef mode, XHYPNO can mesmerized people by flashing at them the crystal it carries on its back. Its victims, completely enthralled will then starts accumulating quantities of flowers and plants for their new master\u2026 While chanting its name enthusiastically : \u201cXhypnoooooo !\u201d"
        },
        {
          "number": 2,
          "name": "HYPNO WINGS",
          "text": "In its Booster mode, XHYPNO\u2019s grow a multitude of hypnotic crystal on its newly sprouted wings\u2026 Making it able to flash and hypnotize entire areas just by flying over !"
        },
        {
          "number": 3,
          "name": "CRYSTAL WINGS",
          "text": "In its Booster mode, XHYPNO\u2019s newly sprouted wings gets reinforced by the crystals that grow on them\u2026 Making them extremely resilient! It can then use them as blades to slash at everything \u2013 and everyone \u2013 standing in its way."
        },
        {
          "number": 4,
          "name": "CRYSTAL BLAST",
          "text": "In its Booster mode, XHYPNO can shoot dangerous crystal shards out of its mouth, allowing it to attack at range, even as it gets immobilized or decide to shield itself behind its wings."
        }
      ],
      "defenses": [
        {
          "number": 1,
          "name": "CRYSTALIDE",
          "text": "In booster mode, Thanks to the resilient property of its crystal wings, XHYPNO can shield itself from almost any attack coming from the front, just by hiding itself behind them."
        },
        {
          "number": 2,
          "name": "CRYSTAL GUST (unused in episode)",
          "text": "In its booster mode, XHYPNO can knock its foes away with a single flap of its mighty crystal wings."
        },
        {
          "number": 3,
          "name": "CRYSTAL COCOONS",
          "text": "As it finally gets sated with boo energy, Xhypno can unleash it in the form of a multitude of crystalline cocoons that sprout from the flowers pile amassed by its mesmerized victims. Inside those cocoons, an army of newborn Xhypno start to grow\u2026 Menacing to unleash a new generation of ghost onto the world if the original one isn\u2019t stopped in time !"
        }
      ]
    }
  },
  {
    "name": "Artiflame",
    "image": {
      "png": require("../Assets/Ghosts/lineup/118_artiflame.png").default,
      "webp": require("../Assets/Ghosts/lineup/118_artiflame.webp").default,
      "width": 383,
      "height": 291
    },
    "card": require("../Assets/Ghosts/Cards/SPECTRAL ARTIFLAME.jpg").default,
    "infos": {
      "kawaii name": "Flame",
      "kaiju name": "Artiflame",
      "level": "4",
      "family": "Spectral",
      "types": "Explosive / Merger",
      "nest": "A lump of coal",
      "receptacle": "A fireworks rocket",
      "age": "Dread",
      "rarity": "Uncommon",
      "deplacement": "Levitation in Kawa\u00ef mode. Flying around like a Chinese dragon in Booster mode.",
      "Weakness": "In addition to its speed and explosiveness, ARTIFLAMME is a surprisingly resilient ghost. Thus, outside of slowly grinding it down, the best way to capture it is to turn the power of its own rocket against it in order to quickly stun it!",
      "": "",
      "attacks": [
        {
          "number": 1,
          "name": "EXPLOBOO",
          "text": "In its Kawa\u00ef mode, FLAMME can create small sized explosions near itself. While those explosions are fairly harmless and won\u2019t do much damage, they\u2019re still enough to sow chaos and fear all around!"
        },
        {
          "number": 2,
          "name": "ROCKETWORK",
          "text": "In its Booster mode ARTIFLAMME can shoot rockets from the two firework horns on its head. This allows it to use the effect of its EXPLOBOO ability at a distance and with a LOT more fire power, as the explosions from the rockets are so powerful they can knock down almost any opponent!"
        }
      ],
      "defenses": [
        {
          "number": 1,
          "name": "ROCKET SPEED",
          "text": "In its Kawa\u00ef mode, FLAMME is already an impressively fast ghost that propels itself forward with the help of its own explosions. But as it gets into Booster mode, ARTIFLAMME becomes one of the fastest Ghosts around! In both case, this speed allows it to dodge and overcome its opponents\u2026 Simply by flying away so fast they can\u2019t hope to catch up!"
        }
      ]
    }
  },
  {
    "name": "Sporofungus",
    "image": {
      "png": require("../Assets/Ghosts/lineup/120_sporofungus.png").default,
      "webp": require("../Assets/Ghosts/lineup/120_sporofungus.webp").default,
      "width": 383,
      "height": 291
    },
    "card": require("../Assets/Ghosts/Cards/MOLECULAR SPOROFUNGUS.jpg").default,
    "infos": {
      "kawaii name": "Flame",
      "kaiju name": "Sporofungus",
      "level": "4",
      "family": "Molecular",
      "types": "Plant / Toxic",
      "nest": "An old dirty tin can",
      "receptacle": "None",
      "age": "Dread",
      "rarity": "Uncommon",
      "deplacement": "Levitation in both its kawa\u00ef and booster mode.",
      "Weakness": "As SPOROFUNGUS is impervious to damage while it stays inside its mushroom cap, the only way to defeat it is to time one\u2019s attacks for when the ghost emerges from its shelter\u2026 Usually right before using its Fungal Fog ability!",
      "": "",
      "attacks": [
        {
          "number": 1,
          "name": "FUNGAL BREATH",
          "text": "in its Kawai form, SPOROFUNGUS can exhale disgusting toxic fumes that linger around and infect people with icky, ugly, dirty pimples and terrible stinky breath\u2026 That smell worse that a rotten fungus!"
        },
        {
          "number": 2,
          "name": "FUNGAL FOG",
          "text": "In its booster mode, SPOROFUNGUS becomes able to shoot out giant clouds of toxic fog, that engulfs entire places in mere seconds\u2026 Turning anyone breathing those fumes inside into actual fungus!"
        }
      ],
      "defenses": [
        {
          "number": 1,
          "name": "HAT TRICK",
          "text": "In his booster mode, SPOROFUNGUS becomes able to retreat inside its own mushroom cap, making itself practically invulnerable in the process! Fortunately, it is also unable to use any other abilities while it stays safely inside\u2026 Forcing it to come out whenever it wishes to attack."
        }
      ]
    }
  },
  {
    "name": "Katastrophe",
    "image": {
      "png": require("../Assets/Ghosts/lineup/123_katastrophe.png").default,
      "webp": require("../Assets/Ghosts/lineup/123_katastrophe.webp").default,
      "width": 383,
      "height": 291
    },
    "card": require("../Assets/Ghosts/Cards/STRANGE KATASTROPHE.jpg").default,
    "infos": {
      "kawaii name": "Bouncy",
      "kaiju name": "Katastrophe",
      "level": "7",
      "family": "Strange",
      "types": "Triplet / Shrieker / Chaotic",
      "nest": "A porcelain vase",
      "receptacle": "None",
      "age": "Panic",
      "rarity": "Very rare",
      "deplacement": "Levitating",
      "Weakness": "As a Shrieker type Ghost, KATASTROPHE relies on the three mouths of its three heads to attack. So the best way to shut it down\u2026 Is to shut them all up!",
      "": "",
      "attacks": [
        {
          "number": 1,
          "name": "KITTY GOO",
          "text": "In its booster mode, KATASTROPHE\u2019s middle head can spray out a disgusting and sticky pink goo that\u2019ll get you stuck on the spot if you get hit. That middle head might be cute\u2026 But it has one dirty breath!"
        },
        {
          "number": 2,
          "name": "KITTY DRAIN",
          "text": "In its booster mode, KATASTROPHE\u2019s right head can shoot out a draining cone, that\u2019ll suck the Boo energy out from anyone caught in it. That right head might be cute\u2026 But it\u2019s starving for some Boo energy!"
        },
        {
          "number": 3,
          "name": "KITTY COMBO",
          "text": "In its booster mode, once they\u2019ve all fused together into one single entity, KATASTROPHE\u2019s three heads can combine their KITTY GOO, DRAIN and HOWL into one devastating howling attack that will leave their prey empty, stunned and sticky. Or in another word : vanquished!"
        }
      ],
      "defenses": [
        {
          "number": 1,
          "name": "KITTY SCREECH",
          "text": "In their Kawa\u00ef mode, the three independent heads that constitute KATASTROPHE can join together into a screeching chant that\u2019ll leave anyone hearing it stun for a beat. Better cover your ears!"
        },
        {
          "number": 2,
          "name": "KITTY HOWL",
          "text": "In its booster mode, KATASTROPHE\u2019s left head can use its howl to shoot out a sound wave that\u2019ll knock anyone back. That left head might be cute\u2026 But it really don\u2019t want you to get close!"
        }
      ]
    }
  },
  {
    "name": "Chronoklok",
    "image": {
      "png": require("../Assets/Ghosts/lineup/124_chronoklok.png").default,
      "webp": require("../Assets/Ghosts/lineup/124_chronoklok.webp").default,
      "width": 383,
      "height": 291
    },
    "card": require("../Assets/Ghosts/Cards/STRANGE CHRONOKLOK.jpg").default,
    "infos": {
      "kawaii name": "Petrifear",
      "kaiju name": "Chronoklok",
      "level": "5",
      "family": "Strange",
      "types": "Petrifier / Merger",
      "nest": "A rubix cube",
      "receptacle": "A smart watch",
      "age": "Awe",
      "rarity": "Rare",
      "deplacement": "Levitating",
      "Weakness": "CHRONOKLOK\u2019s powers originate from the internal gears of the smart watch it merges with. Meaning the best way to defeat it is to drop a proverbial monkey wrench in it!",
      "": "",
      "attacks": [
        {
          "number": 1,
          "name": "PETRICHARGE",
          "text": "In its Kawa\u00ef mode, PETRIFEAR is able to petrify anyone its passes through, bringing them to a complete stop still. It\u2019s like its victims are frozen in time!"
        },
        {
          "number": 2,
          "name": "TIMESTOP BUBBLE",
          "text": "In its Booster mode, CHRONOKLOK is able to charge up its petrifying power and to release it after a delay in the form of a giant petrifying sphere, in which everything grinds to a halt\u2026 Even time itself!"
        }
      ],
      "defenses": [
        {
          "number": 1,
          "name": "STRONG ARMS",
          "text": "In booster mode, CHRONOKLOK can use the steel reinforced bracelet of the watch it merged with as arms with which it can protect itself from incoming attack. Since the watch was built with the intent of being super-resilient, CHRONOKLOK can withstand many attacks this way!"
        },
        {
          "number": 2,
          "name": "COLLINS\u2019 TAUNTS",
          "text": "In booster mode, CHRONOKLOK can use the pre-recorded quotes of Basketball Superstar Michael Collins to taunt at his opponents\u2026 Making them lose their temper and their focus!"
        }
      ]
    }
  },
  {
    "name": "Jellysterry",
    "image": {
      "png": require("../Assets/Ghosts/lineup/127_jellysterry.png").default,
      "webp": require("../Assets/Ghosts/lineup/127_jellysterry.webp").default,
      "width": 383,
      "height": 291
    },
    "card": require("../Assets/Ghosts/Cards/VISCOUS JELLYSTERRY.jpg").default,
    "infos": {
      "kawaii name": "Petrifear",
      "kaiju name": "Jellysterry",
      "level": "4",
      "family": "Viscous",
      "types": "Toxic / Bouncer (then: Ice / Bouncer mid episode)",
      "nest": "A bouncing ball",
      "receptacle": "None",
      "age": "Dread",
      "rarity": "Uncommon",
      "deplacement": "Floating and bouncing.",
      "Weakness": "Due to its unique ADAPTATIVE JELLY ability, Jellystery poses a unique challenge in that it\u2019ll always prove be resilient to the first strategy used against it. Thus, to capture it, you\u2019ll need to stage a two step strategy : first, give it a new type. Two\u2026 Use that new type\u2019s weakness against it!",
      "": "",
      "attacks": [
        {
          "number": 1,
          "name": "JELLY CHARGE",
          "text": "In both its Kawa\u00ef and Booster mode, JELLYSTERY is able to turn anyone its passes through into icky, sticky jelly! Urk. Don\u2019t eat those. They taste awful\u2026 And they\u2019re still people!"
        },
        {
          "number": 2,
          "name": "JELLY BOUNCE",
          "text": "In its Booster mode, JELLYSTERY can throw itself at any solid surface to produce a shockwave of Boo energy that turn anyone it encounters into jelly!"
        },
        {
          "number": 3,
          "name": "FROZEN JELLY CHARGE",
          "text": "In both its Kawa\u00ef and Booster mode, JELLYSTERY is able to turn anyone its passes through into icy, frozen jelly! Urk. Still don\u2019t eat those. They still taste awful\u2026 And they\u2019re still still people!"
        },
        {
          "number": 4,
          "name": "FROZEN JELLY BOUNCE",
          "text": "In its Booster mode, JELLYSTERY can throw itself at any solid surface, bouncing back up to produce a shockwave of Boo energy that turn anyone it encounters into frozen jelly!"
        }
      ],
      "defenses": [
        {
          "number": 1,
          "name": "ADAPTATIVE JELLY",
          "text": "In both its Kawa\u00ef and Booster mode, JELLYSTERY has unique adaptive properties that allow it to modify its primary type when it gets it by an elemental attack for the first time after waking up\u2026 Adopting the type of the attack used! (ie: By getting hit by an ice attack first, it\u2019ll become an ICE type Ghost.)"
        }
      ]
    }
  },
  {
    "name": "Burghorror",
    "image": {
      "png": require("../Assets/Ghosts/lineup/130_burghorror.png").default,
      "webp": require("../Assets/Ghosts/lineup/130_burghorror.webp").default,
      "width": 383,
      "height": 291
    },
    "card": require("../Assets/Ghosts/Cards/VISCOUS BURGHORROR.jpg").default,
    "infos": {
      "kawaii name": "Spig",
      "kaiju name": "Burghorror",
      "level": "4",
      "family": "Viscous",
      "types": "Chaotic / Merger",
      "nest": "A cheese-sauce bottle",
      "receptacle": "A burger",
      "age": "Dread",
      "rarity": "Rare",
      "deplacement": "Floating around",
      "Weakness": "Thanks to its ability to split itself into different layers, BURGHORROR can be quite hard to reliably hit. Meaning the best strategy to defeat it is often to just tie all its layers together\u2026 Maybe with some flexy threads ?",
      "": "",
      "attacks": [
        {
          "number": 1,
          "name": "MELTED GOO",
          "text": "In its Kawa\u00ef mode, SPIG can spit out projectiles made out of melted goo that are sure to leave a dirty stain wherever \u2013 or on whomever \u2013 they land! Disgusting\u2026"
        },
        {
          "number": 2,
          "name": "BURGER BLAST",
          "text": "In its booster mode, BURGHORROR can now spit out huge chunk of burgers to its adversaries in a chaotic swarm of food projectiles. Frisbee Tomatoes, Pickle Torpedo and others are all on the menu! The worst projectiles type from this attack are BURGHORROR\u2019s signature : the Melted Cheese Blast ! As they\u2019ll leave their target completely stuck in a blanket of warm and smelly cheese. Disturbing\u2026 Yet delicious!"
        }
      ],
      "defenses": [
        {
          "number": 1,
          "name": "BURGER SPLIT",
          "text": "In its booster mode, BURGHORROR can dissociate the different layers of its burger, like an accordion unfolding... Allowing it to dodge incoming attacks by splitting its layers up!"
        }
      ]
    }
  },
  {
    "name": "Agia",
    "image": {
      "png": require("../Assets/Ghosts/lineup/131_agia.png").default,
      "webp": require("../Assets/Ghosts/lineup/131_agia.webp").default,
      "width": 383,
      "height": 291
    },
    "card": require("../Assets/Ghosts/Cards/FLEXIBLE AGIA.jpg").default,
    "infos": {
      "kawaii name": "Elatrick",
      "kaiju name": "Agia",
      "level": "7",
      "family": "Flexible",
      "types": "Merger / Hypnotic / Prankster",
      "nest": "A pi\u00f1ata",
      "receptacle": "An inextinguishable birthday candle",
      "age": "Panic",
      "rarity": "Rare",
      "deplacement": "Levitating.",
      "Weakness": "As AGIA\u2019s power originate from the flame of its candle, extinguishing it is the obvious way to defeat it. But like any self-respecting birthday candle, AGIA\u2019s one keep relighting itself! So, in order to put a definitive end to this non-sense, you might have to encase it in something truly hermetic\u2026 Like some fractal boo!",
      "": "",
      "attacks": [
        {
          "number": 1,
          "name": "ELASTO PUNCH",
          "text": "In its Kawa\u00ef mode, ELASTRICK\u2019s arms can stretch endlessly and turns anyone they touch into \u201crubber\u201d. Their victims shrivel up, stretch and squash for a few second before returning to their normal self\u2026 And while the effects are only temporary, they\u2019re usually enough to terrorize anyone affected!"
        },
        {
          "number": 2,
          "name": "AGEMINUS",
          "text": "In its Booster mode, when the flame of its candle burns red, AGIA\u2019s stretchable arms can throw punches that send their victims back into childhood. The punches will start by applying the temporary effect of ELASTO PUNCH, before hypnotizing the victim into behaving like a very young child\u2026 Even though its body remains the same. Except a lot of playing, giggling and crying for toys with this one!"
        },
        {
          "number": 3,
          "name": "AGEPLUS",
          "text": "In its Booster mode, when the flame of its candle burns blue, AGIA\u2019s stretchable arms can throw punches that send their victims straight to the elder age. The punches will start by applying the temporary effect of ELASTO PUNCH, before hypnotizing the victim into behaving like some very old people\u2026 Even though its body remains the same. Expect a lot of slow-walking, complaining about the youth and talking about the weather with this one!"
        }
      ],
      "defenses": [
        {
          "number": 1,
          "name": "BODY STRETCH",
          "text": "In both its Kawa\u00ef and Booster mode, AGIA\u2019s flexible body can stretch out in every way, making it very easy for it to dodge incoming attacks!"
        },
        {
          "number": 2,
          "name": "PRANKANDLE",
          "text": "In its Booster mode, AGIA\u2019s merged with an inextinguishable birthday candle, that can change color to switch between AGIA\u2019s two offensive abilities : AGEMINUS and AGEPLUS. What\u2019s worse: the candle just keep relighting itself every time it gets extinguished! Making it very hard to stop the ghost from using its powers."
        }
      ]
    }
  },
  {
    "name": "Vochaos",
    "image": {
      "png": require("../Assets/Ghosts/lineup/132_vochaos.png").default,
      "webp": require("../Assets/Ghosts/lineup/132_vochaos.webp").default,
      "width": 383,
      "height": 291
    },
    "card": require("../Assets/Ghosts/Cards/VISCOUS VOCHAOS.jpg").default,
    "infos": {
      "kawaii name": "Elatrick",
      "kaiju name": "Vochaos",
      "level": "4",
      "family": "Viscous",
      "types": "Chaotic / Sound",
      "nest": "An old audio recorder",
      "receptacle": "None",
      "age": "Dread",
      "rarity": "Rare",
      "deplacement": "Levitating\u2026 And spinning around, tongue\u2019s out!",
      "Weakness": "As VOCHAOS\u2019s powers all originate from its tongue, the best way to neutralizing the ghost is by immobilizing it completely\u2026 By coating it with some Fractal Boo, for example!",
      "": "",
      "attacks": [
        {
          "number": 1,
          "name": "MINILICK",
          "text": "In its Kawa\u00ef mode, when VOCHAOS passes trough people, he can randomly modify their voices, giving its victim very high or very low pitch\u2026 Sounds scary?"
        },
        {
          "number": 2,
          "name": "MAXILICK",
          "text": "In its Booster mode, VOCHAOS can use its humongous and disgusting tongue to attack people, by giving them a good lick and robbing them of their voices. An attack that is sure to leave you\u2026 Speechless!"
        }
      ],
      "defenses": [
        {
          "number": 1,
          "name": "SPINILICK",
          "text": "In Booster mode, VOCHAOS can send itself and its tongue spinning around, to form a veritable whirlwind of spits n\u2019 licks! Affecting anyone coming too close to him with its MAXILICK ability."
        }
      ]
    }
  },
  // {
  //   "image": {
  //     "png": require("../Assets/Ghosts/lineup/temp/SG1.png").default,
  //     "webp": require("../Assets/Ghosts/lineup/temp/SG1.webp").default,
  //     "width": 383,
  //     "height": 291
  //   },
  // },
  // {
  //   "image": {
  //     "png": require("../Assets/Ghosts/lineup/temp/SG2.png").default,
  //     "webp": require("../Assets/Ghosts/lineup/temp/SG2.webp").default,
  //     "width": 383,
  //     "height": 291
  //   },
  // },
  // {
  //   "image": {
  //     "png": require("../Assets/Ghosts/lineup/temp/SG3.png").default,
  //     "webp": require("../Assets/Ghosts/lineup/temp/SG3.webp").default,
  //     "width": 383,
  //     "height": 291
  //   },
  // },
  // {
  //   "image": {
  //     "png": require("../Assets/Ghosts/lineup/temp/SG4.png").default,
  //     "webp": require("../Assets/Ghosts/lineup/temp/SG4.webp").default,
  //     "width": 383,
  //     "height": 291
  //   },
  // },
  // {
  //   "image": {
  //     "png": require("../Assets/Ghosts/lineup/temp/SG5.png").default,
  //     "webp": require("../Assets/Ghosts/lineup/temp/SG5.webp").default,
  //     "width": 383,
  //     "height": 291
  //   },
  // },
  // {
  //   "image": {
  //     "png": require("../Assets/Ghosts/lineup/temp/SG6.png").default,
  //     "webp": require("../Assets/Ghosts/lineup/temp/SG6.webp").default,
  //     "width": 383,
  //     "height": 291
  //   },
  // },
  // {
  //   "image": {
  //     "png": require("../Assets/Ghosts/lineup/temp/SG7.png").default,
  //     "webp": require("../Assets/Ghosts/lineup/temp/SG7.webp").default,
  //     "width": 383,
  //     "height": 291
  //   },
  // },
  // {
  //   "image": {
  //     "png": require("../Assets/Ghosts/lineup/temp/SG8.png").default,
  //     "webp": require("../Assets/Ghosts/lineup/temp/SG8.webp").default,
  //     "width": 383,
  //     "height": 291
  //   },
  // },
  // {
  //   "image": {
  //     "png": require("../Assets/Ghosts/lineup/temp/SG9.png").default,
  //     "webp": require("../Assets/Ghosts/lineup/temp/SG9.webp").default,
  //     "width": 383,
  //     "height": 291
  //   },
  // },
  // {
  //   "image": {
  //     "png": require("../Assets/Ghosts/lineup/temp/SG10.png").default,
  //     "webp": require("../Assets/Ghosts/lineup/temp/SG10.webp").default,
  //     "width": 383,
  //     "height": 291
  //   },
  // },
  // {
  //   "image": {
  //     "png": require("../Assets/Ghosts/lineup/temp/SG11.png").default,
  //     "webp": require("../Assets/Ghosts/lineup/temp/SG11.webp").default,
  //     "width": 383,
  //     "height": 291
  //   },
  // },
  // {
  //   "image": {
  //     "png": require("../Assets/Ghosts/lineup/temp/SG12.png").default,
  //     "webp": require("../Assets/Ghosts/lineup/temp/SG12.webp").default,
  //     "width": 383,
  //     "height": 291
  //   },
  // },
  // {
  //   "image": {
  //     "png": require("../Assets/Ghosts/lineup/temp/SG13.png").default,
  //     "webp": require("../Assets/Ghosts/lineup/temp/SG13.webp").default,
  //     "width": 383,
  //     "height": 291
  //   },
  // },
  // {
  //   "image": {
  //     "png": require("../Assets/Ghosts/lineup/temp/SG14.png").default,
  //     "webp": require("../Assets/Ghosts/lineup/temp/SG14.webp").default,
  //     "width": 383,
  //     "height": 291
  //   },
  // },
  // {
  //   "image": {
  //     "png": require("../Assets/Ghosts/lineup/temp/SG15.png").default,
  //     "webp": require("../Assets/Ghosts/lineup/temp/SG15.webp").default,
  //     "width": 383,
  //     "height": 291
  //   },
  // },
  // {
  //   "image": {
  //     "png": require("../Assets/Ghosts/lineup/temp/SG16.png").default,
  //     "webp": require("../Assets/Ghosts/lineup/temp/SG16.webp").default,
  //     "width": 383,
  //     "height": 291
  //   },
  // },
  // {
  //   "image": {
  //     "png": require("../Assets/Ghosts/lineup/temp/SG17.png").default,
  //     "webp": require("../Assets/Ghosts/lineup/temp/SG17.webp").default,
  //     "width": 383,
  //     "height": 291
  //   },
  // },
  // {
  //   "image": {
  //     "png": require("../Assets/Ghosts/lineup/temp/SG18.png").default,
  //     "webp": require("../Assets/Ghosts/lineup/temp/SG18.webp").default,
  //     "width": 383,
  //     "height": 291
  //   },
  // },

  ...fakeGhosts,

]