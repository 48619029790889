import { useState } from 'react';
import HeroModal from '../Modal/HeroModal';
import Modal from '../Modal/Modal';

const Heroes = () => {

  const [modalActive, setModalActive] = useState(null);

  return <section id="Heroes" className="bg-[#101010]">
    <div className="h-0 relative">
      <div className="absolute bottom-0 mb-[-5%]">
        <picture>
          <source srcSet={require('../../Assets/Heroes/header.webp').default} type="image/webp" />
          <source srcSet={require('../../Assets/Heroes/header.png').default} type="image/png" />
          <img alt="" width="1920" height="356" src={require('../../Assets/Heroes/header.png').default} />
        </picture>
        <h1 className="absolute flex justify-center items-center text-white text-[4.8vw] font-extrabold tracking-wider uppercase inset-0 mb-[1%]" style={{
          textShadow: `0px 2px 6px #333`,
        }}>
          Meet the heroes
        </h1>
      </div>
    </div>
    <div className="relative pt-4">

      <div className="text-white z-10 px-10 md:px-[15%] md:text-[3vw] md:leading-tight text-justify leading-snug" style={{
        textShadow: `0px 0px 4px #00ff00, 0px 0px 4px #00ff00`,
      }}>Liv, Andy, and Mike are three teens who form the ultra-secret Ghostforce, a squad of superheroes with ghostly powers!<br /><br />
        With the help of the mysterious Glowboo and an arsenal of high-tech invented by their mentor, Miss Jones, our heroes battle and capture the ghosts that haunt New York City… before racing back to school for their next class!
      </div>
      <div className="overflow-x-hidden">
        <div className="relative aspect-[1920/1110] w-[114%] ml-[-7%] md:w-auto md:ml-0">
          <div className="absolute inset-0 flex flex-row z-20">
            <div className="w-[39%] cursor-pointer" onClick={() => setModalActive(0)}></div>
            <div className="w-[22%] cursor-pointer" onClick={() => setModalActive(1)}></div>
            <div className="w-[39%] cursor-pointer" onClick={() => setModalActive(2)}></div>
          </div>
          <video width="1920" height="1110" loop autoPlay={true} muted playsInline className="!max-h-full">
            <source width="1920" height="1110" src={require("../../Assets/Heroes/anim.mp4").default} />
          </video>
        </div>
      </div>
    </div>
    <div className="relative z-10">
      <picture>
        <source srcSet={require('../../Assets/Heroes/footer.webp').default} type="image/webp" />
        <source srcSet={require('../../Assets/Heroes/footer.png').default} type="image/png" />
        <img alt="" width="1920" height="157" loading="lazy" src={require('../../Assets/Heroes/footer.png').default} />
      </picture>
    </div>

    {modalActive !== null ? <Modal onClose={() => setModalActive(null)}>
      <HeroModal index={modalActive} />
    </Modal> : null}
  </section >;
}


export default Heroes;