const Home = () => {
  return <section id="Home" className="bg-cover bg-home bg-bottom flex items-center md:pt-20 flex-col overflow-hidden relative z-0 pb-[10%]" style={{
    animation: "4s cubic-bezier(0.23, 1, 0.320, 1) IntroScrollDown forwards"
  }}>
    <h1 title="Ghostforce" className="block h-[20%] max-w-[90%] sm:max-w-[80%] mt-[5%] md:mt-[10%] md:mt-0">
      <picture className="flex justify-center">
        <source srcSet={require("../../Assets/Home/logo.webp").default} type="image/webp" />
        <source srcSet={require("../../Assets/Home/logo.png").default} type="image/png" />
        <img alt="Ghostforce logo" width="1511" height="405" src={require("../../Assets/Home/logo.png").default} className="max-h-full w-[800px] max-w-[90%]" style={{
          animation: "2.5s cubic-bezier(0.23, 1, 0.320, 1) IntroLogo forwards"
        }} />
      </picture>
    </h1>
    <div className="relative hidden md:block w-full aspect-[1920/729] overflow-hidden">
      <picture>
        <source srcSet={require('../../Assets/Home/desk_krush.webp').default} type="image/webp" />
        <source srcSet={require('../../Assets/Home/desk_krush.png').default} type="image/png" />
        <img alt="Krush" width="1920" height="729" src={require('../../Assets/Home/desk_krush.png').default} className="introPerso" />
      </picture>
      <picture>
        <source srcSet={require('../../Assets/Home/desk_fury.webp').default} type="image/webp" />
        <source srcSet={require('../../Assets/Home/desk_fury.png').default} type="image/png" />
        <img alt="Fury" width="1920" height="729" src={require('../../Assets/Home/desk_fury.png').default} className="introPerso absolute top-0 left-0" />
      </picture>
      <picture>
        <source srcSet={require('../../Assets/Home/desk_myst.webp').default} type="image/webp" />
        <source srcSet={require('../../Assets/Home/desk_myst.png').default} type="image/png" />
        <img alt="Myst" width="1920" height="729" src={require('../../Assets/Home/desk_myst.png').default} className="introPerso absolute top-0 left-0" />
      </picture>
      <picture>
        <source srcSet={require('../../Assets/Home/desk_glowboo.webp').default} type="image/webp" />
        <source srcSet={require('../../Assets/Home/desk_glowboo.png').default} type="image/png" />
        <img alt="Glowboo" width="1920" height="729" src={require('../../Assets/Home/desk_glowboo.png').default} className="introPerso absolute top-0 left-0" />
      </picture>
    </div>

    <div className="relative block md:hidden w-full aspect-[1920/1080] overflow-hidden">
      <picture>
        <source srcSet={require('../../Assets/Home/mobile_krush.webp').default} type="image/webp" />
        <source srcSet={require('../../Assets/Home/mobile_krush.png').default} type="image/png" />
        <img alt="Krush" width="1920" height="1080" src={require('../../Assets/Home/mobile_krush.png').default} className="introPerso" />
      </picture>
      <picture>
        <source srcSet={require('../../Assets/Home/mobile_fury.webp').default} type="image/webp" />
        <source srcSet={require('../../Assets/Home/mobile_fury.png').default} type="image/png" />
        <img alt="Fury" width="1920" height="1080" src={require('../../Assets/Home/mobile_fury.png').default} className="introPerso absolute top-0 left-0" />
      </picture>
      <picture>
        <source srcSet={require('../../Assets/Home/mobile_myst.webp').default} type="image/webp" />
        <source srcSet={require('../../Assets/Home/mobile_myst.png').default} type="image/png" />
        <img alt="Myst" width="1920" height="1080" src={require('../../Assets/Home/mobile_myst.png').default} className="introPerso absolute top-0 left-0" />
      </picture>
      <picture>
        <source srcSet={require('../../Assets/Home/mobile_glowboo.webp').default} type="image/webp" />
        <source srcSet={require('../../Assets/Home/mobile_glowboo.png').default} type="image/png" />
        <img alt="Glowboo" width="1920" height="1080" src={require('../../Assets/Home/mobile_glowboo.png').default} className="introPerso absolute top-0 left-0" />
      </picture>
    </div>

    <div className="w-full z-20 mt-[-2%]">
      <picture className="flex justify-center">
        <source srcSet={require("../../Assets/Home/prods.webp").default} type="image/webp" />
        <source srcSet={require("../../Assets/Home/prods.png").default} type="image/png" />
        <img alt="Production partners" width="761" height="133" src={require('../../Assets/Home/prods.png').default} className="min-w-[200px] max-w-[30%] mb-[1vw]" />
      </picture>
    </div>
  </section>;
}


export default Home;