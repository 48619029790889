import { useState } from 'react';
import Modal from '../Modal/Modal';
import { WallpaperModal } from './modal/WallpaperModal';

const Detectoghost = () => {
  const [modalActive, setModalActive] = useState(false);

  return <section id="Wallpapers" className="relative z-10">



    <div className="z-20 relative h-0">
      <img alt="" loading="lazy" src={require('../../Assets/Detectoghost/header.png').default} className="absolute top-0 mt-[-3%]" />
    </div>
    <div className="relative pt-[1px] !bg-contain mt-[-1%] cursor-pointer" style={{ background: `#101010` }} onClick={() => setModalActive(true)}>

      <h1 className="absolute top-[5.3%] w-full flex justify-center items-center text-white text-[4vw] font-extrabold tracking-wider uppercase z-10" style={{
        textShadow: `0px 2px 6px #333`,
      }}>
        Wallpapers
      </h1>

      <div className="absolute top-[30%] w-[24%] h-[24%] left-[38%] pointer-events-none z-30">
        <img alt="" loading="lazy" src={require('../../Assets/Detectoghost/link.png').default} />
      </div>

      <div className="relative">
        <img alt="" loading="lazy" src={require('../../Assets/Detectoghost/bg.jpg').default} />
      </div>

    </div>
    <div className="h-0 relative z-10">
      <img alt="" loading="lazy" src={require('../../Assets/Detectoghost/footer.png').default} className="absolute top-0 mt-[-3%]" />
    </div>

    {modalActive ? <Modal onClose={() => setModalActive(null)}>
      <WallpaperModal />
    </Modal> : null}
  </section>;
}

export default Detectoghost;