import { ParallaxBanner } from "react-scroll-parallax";
import useElementOnScreen from "../../Functions/UseElementOnScreen";

const GhostSep = () => {
  const [containerRef, isVisible] = useElementOnScreen({ root: null, rootMargin: '0px', threshold: 0.4 });

  // return <div ref={containerRef} className="bg-gs bg-ghostsep bg-fixed bg-cover bg-center mt-[-4.5%] overflow-hidden">
  //   <div className={`relative w-full pb-[40%] overflow-hidden sep-effect ${isVisible ? 'active' : ''}`}>
  //     <img loading="lazy" src={require("../../Assets/Ghosts/kwamis/134_Bubble-Brush_kawai0001.png").default} className="floaty absolute z-10 left-[2%] top-[10%] w-[30%]" />
  //     <img loading="lazy" src={require("../../Assets/Ghosts/kwamis/114_krikkrok_kawai_0001.png").default} className="floaty absolute z-10 left-[-10%] top-[0%] w-[35%]" />
  //     <img loading="lazy" src={require("../../Assets/Ghosts/kwamis/103_zipzap_kawai_0001.png").default} className="floaty absolute z-10 left-[0%] top-[-5%] w-[60%]" />
  //     <img loading="lazy" src={require("../../Assets/Ghosts/kwamis/117_xhypno_kawai_0001.png").default} className="floaty absolute z-10 left-[45%] w-[26%] top-[10%]" />
  //     <img loading="lazy" src={require("../../Assets/Ghosts/kwamis/133_cyclopee_kawai0001.png").default} className="floaty absolute z-10 right-[16%] top-[25%] w-[30%]" />
  //     <img loading="lazy" src={require("../../Assets/Ghosts/kwamis/109_mizuo_kawai_0001.png").default} className="floaty absolute z-10 right-[-10%] w-[45%] top-[10%]" />
  //   </div>
  // </div>;

  const ghostsLayer = {
    speed: 0.5,
    translateY: [0, -10],
    shouldAlwaysCompleteAnimation: true,
    expanded: false,
    children: (
      <>
        <img alt="Ghost" loading="lazy" src={require("../../Assets/Ghosts/kwamis/134_Bubble-Brush_kawai0001.png").default} className="floaty absolute z-10 left-[2%] top-[10%] w-[30%]" />
        <img alt="Ghost" loading="lazy" src={require("../../Assets/Ghosts/kwamis/114_krikkrok_kawai_0001.png").default} className="floaty absolute z-10 left-[-10%] top-[0%] w-[35%]" />
        <img alt="Ghost" loading="lazy" src={require("../../Assets/Ghosts/kwamis/103_zipzap_kawai_0001.png").default} className="floaty absolute z-10 left-[0%] top-[-5%] w-[60%]" />
        <img alt="Ghost" loading="lazy" src={require("../../Assets/Ghosts/kwamis/117_xhypno_kawai_0001.png").default} className="floaty absolute z-10 left-[45%] w-[26%] top-[10%]" />
        <img alt="Ghost" loading="lazy" src={require("../../Assets/Ghosts/kwamis/133_cyclopee_kawai0001.png").default} className="floaty absolute z-10 right-[16%] top-[25%] w-[30%]" />
        <img alt="Ghost" loading="lazy" src={require("../../Assets/Ghosts/kwamis/109_mizuo_kawai_0001.png").default} className="floaty absolute z-10 right-[-10%] w-[45%] top-[10%]" />
      </>
    ),
  };

  return <div ref={containerRef} className={`sep-effect ${isVisible ? 'active' : ''}`}>
    <ParallaxBanner
      layers={[{ image: require('../../Assets/Ghosts/sep.jpg').default, speed: -15 }, ghostsLayer]}
      className={`aspect-[5/2] mt-[-4.5%] bg-contain relative`}
    >

    </ParallaxBanner>
  </div>;

}



const GhostSep3 = () => {
  const [containerRef, isVisible] = useElementOnScreen({ root: null, rootMargin: '0px', threshold: 0.4 });

  // return <div ref={containerRef} className="bg-gs bg-ghostsep3 bg-fixed bg-cover bg-center mt-[0%] overflow-hidden">
  //   <div className={`relative w-full pb-[40%] overflow-hidden sep-effect ${isVisible ? 'active' : ''}`}>
  //     <img loading="lazy" src={require("../../Assets/Ghosts/kwamis/132_vochaos_kawai_0001.png").default} className="floaty absolute z-10 left-[15%] top-[30%] w-[25%]" />
  //     <img loading="lazy" src={require("../../Assets/Ghosts/kwamis/102_glougloux_kawai_0002.png").default} className="floaty absolute z-10 left-[-10%] top-[25%] w-[40%]" />
  //     <img loading="lazy" src={require("../../Assets/Ghosts/kwamis/107_raijin_kawai_0001.png").default} className="floaty absolute z-10 left-[35%] top-[30%] w-[35%]" />
  //     <img loading="lazy" src={require("../../Assets/Ghosts/kwamis/126_chaorion_kawai0001.png").default} className="floaty absolute z-10 right-[0%] top-[10%] w-[45%]" />
  //     <img loading="lazy" src={require("../../Assets/Ghosts/kwamis/101_bananice_kawai_0001.png").default} className="floaty absolute z-10 right-[-8%] w-[25%] top-[35%]" />
  //   </div>
  // </div>;


  const ghostsLayer = {
    speed: 0.5,
    translateY: [0, -10],
    shouldAlwaysCompleteAnimation: true,
    expanded: false,
    children: (
      <>
        <img alt="Ghost" role="presentation" loading="lazy" src={require("../../Assets/Ghosts/kwamis/132_vochaos_kawai_0001.png").default} className="floaty absolute z-10 left-[15%] top-[35%] w-[25%]" />
        <img alt="Ghost" role="presentation" loading="lazy" src={require("../../Assets/Ghosts/kwamis/102_glougloux_kawai_0002.png").default} className="floaty absolute z-10 left-[-10%] top-[30%] w-[40%]" />
        <img alt="Ghost" role="presentation" loading="lazy" src={require("../../Assets/Ghosts/kwamis/107_raijin_kawai_0001.png").default} className="floaty absolute z-10 left-[35%] top-[35%] w-[35%]" />
        <img alt="Ghost" role="presentation" loading="lazy" src={require("../../Assets/Ghosts/kwamis/126_chaorion_kawai0001.png").default} className="floaty absolute z-10 right-[0%] top-[15%] w-[45%]" />
        <img alt="Ghost" role="presentation" loading="lazy" src={require("../../Assets/Ghosts/kwamis/101_bananice_kawai_0001.png").default} className="floaty absolute z-10 right-[-8%] w-[25%] top-[40%]" />
      </>
    ),
  };

  return <div ref={containerRef} className={`sep-effect ${isVisible ? 'active' : ''}`}>
    <ParallaxBanner
      layers={[{ image: require('../../Assets/Ghosts/sep3.jpg').default, speed: -15 }, ghostsLayer]}
      className={`aspect-[5/2.2] mt-[-1%] bg-contain relative`}
    >

    </ParallaxBanner>
  </div>;
}

const GhostSep2 = () => {
  const [containerRef, isVisible] = useElementOnScreen({ root: null, rootMargin: '0px', threshold: 0.4 });

  // return <div ref={containerRef} className="bg-gs bg-ghostsep2 bg-fixed bg-cover bg-center mt-[-15%] overflow-hidden">
  //   <div className={`relative w-full pb-[55%] overflow-hidden sep-effect ${isVisible ? 'active' : ''}`}>
  //     <img loading="lazy" src={require("../../Assets/Ghosts/kwamis/123_catastroph_kawai_0001.png").default} className="floaty absolute z-10 left-[20%] top-[40%] w-[45%]" />
  //     <img loading="lazy" src={require("../../Assets/Ghosts/kwamis/112_mastaar_kawai_0001.png").default} className="floaty absolute z-10 left-[-10%] top-[35%] w-[44%]" />
  //     <img loading="lazy" src={require("../../Assets/Ghosts/kwamis/115_sharkoak_kawai_0001.png").default} className="floaty absolute z-10 right-[14%] top-[45%] w-[28%]" />
  //     <img loading="lazy" src={require("../../Assets/Ghosts/kwamis/120_sporofungus_kawai_0001.png").default} className="floaty absolute z-10 right-[-12%] top-[35%] w-[45%]" />
  //     <img loading="lazy" src={require("../../Assets/Ghosts/kwamis/149_batata_kawai0001.png").default} className="floaty absolute z-10 right-[-8%] w-[25%] top-[25%]" />
  //   </div>
  // </div>;


  const ghostsLayer = {
    speed: 0.5,
    translateY: [0, -10],
    shouldAlwaysCompleteAnimation: true,
    expanded: false,
    children: (
      <>
        <img alt="Ghost" role="presentation" loading="lazy" src={require("../../Assets/Ghosts/kwamis/123_catastroph_kawai_0001.png").default} className="floaty absolute z-10 left-[20%] top-[40%] w-[45%]" />
        <img alt="Ghost" role="presentation" loading="lazy" src={require("../../Assets/Ghosts/kwamis/112_mastaar_kawai_0001.png").default} className="floaty absolute z-10 left-[-10%] top-[35%] w-[44%]" />
        <img alt="Ghost" role="presentation" loading="lazy" src={require("../../Assets/Ghosts/kwamis/115_sharkoak_kawai_0001.png").default} className="floaty absolute z-10 right-[14%] top-[45%] w-[28%]" />
        <img alt="Ghost" role="presentation" loading="lazy" src={require("../../Assets/Ghosts/kwamis/120_sporofungus_kawai_0001.png").default} className="floaty absolute z-10 right-[-12%] top-[35%] w-[45%]" />
        <img alt="Ghost" role="presentation" loading="lazy" src={require("../../Assets/Ghosts/kwamis/149_batata_kawai0001.png").default} className="floaty absolute z-10 right-[-8%] w-[25%] top-[25%]" />
      </>
    ),
  };

  return <div ref={containerRef} className={`sep-effect ${isVisible ? 'active' : ''}`}>
    <ParallaxBanner
      layers={[{ image: require('../../Assets/Ghosts/sep2.jpg').default, speed: -15 }, ghostsLayer]}
      className={`aspect-[5/2.3] mt-[-4.5%] bg-contain relative`}
    >

    </ParallaxBanner>
  </div>;
}

const GhostSep4 = () => {
  const [containerRef, isVisible] = useElementOnScreen({ root: null, rootMargin: '0px', threshold: 0.4 });

  // return <div ref={containerRef} className="bg-gs bg-ghostsep4 bg-fixed bg-cover bg-center mt-[-2%]">
  //   <div className={`relative w-full pb-[40%] overflow-hidden sep-effect ${isVisible ? 'active' : ''}`}>
  // <img loading="lazy" src={require("../../Assets/Ghosts/kwamis/118_artifeu_kawai_0001.png").default} className="floaty absolute z-10 left-[-10%] top-[0%] w-[45%]" />
  // <img loading="lazy" src={require("../../Assets/Ghosts/kwamis/116_arak_kawai_0001.png").default} className="floaty absolute z-10 left-[20%] top-[25%] w-[24%]" />
  // <img loading="lazy" src={require("../../Assets/Ghosts/kwamis/105_jinjoke_kawai0001.png").default} className="floaty absolute z-10 left-[31%] top-[30%] w-[32%]" />
  // <img loading="lazy" src={require("../../Assets/Ghosts/kwamis/113_Gmagicard_Kawai_0001.png").default} className="floaty absolute z-10 right-[20%] top-[4%] w-[28%]" />
  // <img loading="lazy" src={require("../../Assets/Ghosts/kwamis/137_graffurious_kawai0001.png").default} className="floaty absolute z-10 right-[6%] w-[32%] top-[20%]" />
  // <img loading="lazy" src={require("../../Assets/Ghosts/kwamis/104_spong_kawai_0001.png").default} className="floaty absolute z-10 right-[-13%] w-[35%] top-[15%]" />
  //   </div>
  // </div>;

  const ghostsLayer = {
    speed: 0.5,
    translateY: [0, -10],
    shouldAlwaysCompleteAnimation: true,
    expanded: false,
    children: (
      <>
        <img alt="Ghost" role="presentation" loading="lazy" src={require("../../Assets/Ghosts/kwamis/118_artifeu_kawai_0001.png").default} className="floaty absolute z-10 left-[-10%] top-[5%] w-[45%]" />
        <img alt="Ghost" role="presentation" loading="lazy" src={require("../../Assets/Ghosts/kwamis/116_arak_kawai_0001.png").default} className="floaty absolute z-10 left-[20%] top-[30%] w-[24%]" />
        <img alt="Ghost" role="presentation" loading="lazy" src={require("../../Assets/Ghosts/kwamis/105_jinjoke_kawai0001.png").default} className="floaty absolute z-10 left-[31%] top-[35%] w-[32%]" />
        <img alt="Ghost" role="presentation" loading="lazy" src={require("../../Assets/Ghosts/kwamis/113_Gmagicard_Kawai_0001.png").default} className="floaty absolute z-10 right-[20%] top-[9%] w-[28%]" />
        <img alt="Ghost" role="presentation" loading="lazy" src={require("../../Assets/Ghosts/kwamis/137_graffurious_kawai0001.png").default} className="floaty absolute z-10 right-[6%] w-[37%] top-[20%]" />
        <img alt="Ghost" role="presentation" loading="lazy" src={require("../../Assets/Ghosts/kwamis/104_spong_kawai_0001.png").default} className="floaty absolute z-10 right-[-13%] w-[40%] top-[15%]" />
      </>
    ),
  };

  return <div ref={containerRef} className={`sep-effect ${isVisible ? 'active' : ''}`}>
    <ParallaxBanner
      layers={[{ image: require('../../Assets/Ghosts/sep4.jpg').default, speed: -15 }, ghostsLayer]}
      className={`aspect-[5/2] mt-[-4.5%] bg-contain relative`}
    >

    </ParallaxBanner>
  </div>;
}

export { GhostSep2, GhostSep3, GhostSep4 };
export default GhostSep;