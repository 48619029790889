import Socials from "./Socials";

export default function Footer() {
  return (
    <div className="relative mt-[0%]">
      <picture>
        <source srcSet={require("../../Assets/Footer/background.webp").default} type="image/webp" />
        <source srcSet={require("../../Assets/Footer/background.jpg").default} type="image/jpg" />
        <img alt="" width="1920" height="878" loading="lazy" src={require("../../Assets/Footer/background.jpg").default} />
      </picture>
      <div className="absolute top-[6%] w-full">
        <div className="hidden md:flex justify-center">
          <Socials />
        </div>
        <div className="text-center w-full uppercase text-white mt-1 md:mt-[0%] text-xs md:text-xs lg:text-base 2xl:text-lg">
          <a href="#" className="whitespace-nowrap">About Zag</a> |&nbsp;
          <a href="#" className="whitespace-nowrap">Become a partner</a> |&nbsp;
          <a href="#" className="whitespace-nowrap">Contact us</a> |&nbsp;
          <a href="#" className="whitespace-nowrap">Terms of use</a> |&nbsp;
          <a href="#" className="whitespace-nowrap">Privacy policy</a>
        </div>
      </div>

      <div className="flex flex-col items-center justify-items-end w-full absolute bottom-[4%] text-white text-[0.6rem] md:text-sm lg:text-base">
        <img alt="Zag Logo" width="756" height="334" loading="lazy" src={require('../../Assets/Footer/Logo ZAG 2022.png').default} className="w-[20%] block" />
        © 2022 ZAGTOON - KidsMe. All Rights Reserved.
      </div>
    </div>
  );
}
