const wallpapers = [
  [

    {
      "name": "GF_ANDY_FURY",
      "image": require("../../../Assets/Detectoghost/wallpapers/GF_ANDY_FURY.jpg").default,
      "thumb": require("../../../Assets/Detectoghost/wallpapers/thumbs/GF_ANDY_FURY.jpg").default,
    },
    {
      "name": "GF_FURY",
      "image": require("../../../Assets/Detectoghost/wallpapers/GF_FURY.jpg").default,
      "thumb": require("../../../Assets/Detectoghost/wallpapers/thumbs/GF_FURY.jpg").default,
    },
    {
      "name": "GF_LIV_MYST",
      "image": require("../../../Assets/Detectoghost/wallpapers/GF_LIV_MYST.jpg").default,
      "thumb": require("../../../Assets/Detectoghost/wallpapers/thumbs/GF_LIV_MYST.jpg").default,
    },
    {
      "name": "GF_MYST",
      "image": require("../../../Assets/Detectoghost/wallpapers/GF_MYST.jpg").default,
      "thumb": require("../../../Assets/Detectoghost/wallpapers/thumbs/GF_MYST.jpg").default,
    },
    {
      "name": "GF_MIKE_KRUSH",
      "image": require("../../../Assets/Detectoghost/wallpapers/GF_MIKE_KRUSH.jpg").default,
      "thumb": require("../../../Assets/Detectoghost/wallpapers/thumbs/GF_MIKE_KRUSH.jpg").default,
    },
    {
      "name": "GF_KRUSH",
      "image": require("../../../Assets/Detectoghost/wallpapers/GF_KRUSH.jpg").default,
      "thumb": require("../../../Assets/Detectoghost/wallpapers/thumbs/GF_KRUSH.jpg").default,
    },
    {
      "name": "GF_POSTER_GROUP_01",
      "image": require("../../../Assets/Detectoghost/wallpapers/GF_POSTER_GROUP_01.jpg").default,
      "thumb": require("../../../Assets/Detectoghost/wallpapers/thumbs/GF_POSTER_GROUP_01.jpg").default,
    },
    {
      "name": "GF_POSTER_GROUP_03",
      "image": require("../../../Assets/Detectoghost/wallpapers/GF_POSTER_GROUP_03.jpg").default,
      "thumb": require("../../../Assets/Detectoghost/wallpapers/thumbs/GF_POSTER_GROUP_03.jpg").default,
    },
    {
      "name": "GF_POSTER_GROUP_04",
      "image": require("../../../Assets/Detectoghost/wallpapers/GF_POSTER_GROUP_04.jpg").default,
      "thumb": require("../../../Assets/Detectoghost/wallpapers/thumbs/GF_POSTER_GROUP_04.jpg").default,
    },
  ],
  [

    {
      "name": "GF_FURY_Horizontal",
      "image": require("../../../Assets/Detectoghost/wallpapers/GF_FURY_Horizontal.jpg").default,
      "thumb": require("../../../Assets/Detectoghost/wallpapers/thumbs/GF_FURY_Horizontal.jpg").default,
      "width": '20%',
    },
    {
      "name": "GF_MYST_Horizontal",
      "image": require("../../../Assets/Detectoghost/wallpapers/GF_MYST_Horizontal.jpg").default,
      "thumb": require("../../../Assets/Detectoghost/wallpapers/thumbs/GF_MYST_Horizontal.jpg").default,
      "width": '20%',
    },
    {
      "name": "GF_KRUSH_Horizontal",
      "image": require("../../../Assets/Detectoghost/wallpapers/GF_KRUSH_Horizontal.jpg").default,
      "thumb": require("../../../Assets/Detectoghost/wallpapers/thumbs/GF_KRUSH_Horizontal.jpg").default,
      "width": '20%',
    },
    {
      "name": "GF_Main-Poster_Horizontal",
      "image": require("../../../Assets/Detectoghost/wallpapers/GF_Main-Poster_Horizontal.jpg").default,
      "thumb": require("../../../Assets/Detectoghost/wallpapers/thumbs/GF_Main-Poster_Horizontal.jpg").default,
      "width": '20%',
    },
    {
      "name": "GF_Main-Poster_Square",
      "image": require("../../../Assets/Detectoghost/wallpapers/GF_Main-Poster_Square.jpg").default,
      "thumb": require("../../../Assets/Detectoghost/wallpapers/thumbs/GF_Main-Poster_Square.jpg").default,
      "width": "11.7%",
    },
    {
      "name": "GF_Main-Poster",
      "image": require("../../../Assets/Detectoghost/wallpapers/GF_Main-Poster.jpg").default,
      "thumb": require("../../../Assets/Detectoghost/wallpapers/thumbs/GF_Main-Poster.jpg").default,
      "width": "7.04%",
    },

  ],
  [
    {
      "name": "Times Square",
      "image": require("../../../Assets/Detectoghost/wallpapers/Times Square.jpg").default,
      "thumb": require("../../../Assets/Detectoghost/wallpapers/thumbs/Times Square.jpg").default,
      "width": '16.66%',
    },
    {
      "name": "Laboratory 01",
      "image": require("../../../Assets/Detectoghost/wallpapers/Laboratory 01.jpg").default,
      "thumb": require("../../../Assets/Detectoghost/wallpapers/thumbs/Laboratory 01.jpg").default,
      "width": '16.66%',
    },
    {
      "name": "Laboratory 02",
      "image": require("../../../Assets/Detectoghost/wallpapers/Laboratory 02.jpg").default,
      "thumb": require("../../../Assets/Detectoghost/wallpapers/thumbs/Laboratory 02.jpg").default,
      "width": '16.66%',
    },
    {
      "name": "Laboratory 03",
      "image": require("../../../Assets/Detectoghost/wallpapers/Laboratory 03.jpg").default,
      "thumb": require("../../../Assets/Detectoghost/wallpapers/thumbs/Laboratory 03.jpg").default,
      "width": '16.66%',
    },
    {
      "name": "School Hall",
      "image": require("../../../Assets/Detectoghost/wallpapers/School Hall.jpg").default,
      "thumb": require("../../../Assets/Detectoghost/wallpapers/thumbs/School Hall.jpg").default,
      "width": '16.66%',
    },
    {
      "name": "Principal\u2019s Office",
      "image": require("../../../Assets/Detectoghost/wallpapers/Principal\u2019s Office.jpg").default,
      "thumb": require("../../../Assets/Detectoghost/wallpapers/thumbs/Principal\u2019s Office.jpg").default,
      "width": '16.66%',
    },
  ]
];


const ratios = {
  "GF_ANDY_FURY": 0.6720430107526881,
  "GF_FURY": 0.75,
  "GF_FURY_Horizontal": 1.7772511848341233,
  "GF_KRUSH": 0.75,
  "GF_KRUSH_Horizontal": 1.7772511848341233,
  "GF_LIV_MYST": 0.6723442402510085,
  "GF_MIKE_KRUSH": 0.6720430107526881,
  "GF_MYST": 0.75,
  "GF_MYST_Horizontal": 1.7772511848341233,
  "GF_Main-Poster": 0.562429696287964,
  "GF_Main-Poster_Horizontal": 1.7772511848341233,
  "GF_Main-Poster_Square": 1,
  "GF_POSTER_GROUP_01": 1,
  "GF_POSTER_GROUP_03": 0.7455268389662028,
  "GF_POSTER_GROUP_04": 0.7458333333333333,
  "Laboratory 01": 1.7772511848341233,
  "Laboratory 02": 1.7772511848341233,
  "Laboratory 03": 1.7772511848341233,
  "Principal\u2019s Office": 1.7772511848341233,
  "School Hall": 1.7772511848341233,
  "Times Square": 1.7793594306049823
};

export const WallpaperModal = () => {

  const download = (data) => {
    console.log(data);
    var element = document.createElement("a");
    var file = new Blob(
      [
        '\\' + data,
      ],
      { type: "image/*" }
    );
    element.href = URL.createObjectURL(file);
    element.download = "image.jpg";
    element.click();
  };

  return <div style={{ backgroundImage: `url(${require('../../../Assets/Detectoghost/modal.jpg').default})` }} className="h-full bg-cover relative bg-no-repeat bg-top">
    <div className="flex justify-center">
      <div className="pt-[5vh] md:pt-[3.5vh] w-full max-w-[200px] h-[14vh] md:max-w-none flex justify-center items-center text-white text-[4vh] md:text-[5vh] lg:text-[7vh] font-bold italic tracking-wider uppercase text-center leading-none" style={{
        textShadow: `0px 2px 6px #333, 0px 2px 6px #333, 0px 2px 6px #333`,
      }}>
        Select your wallpaper
      </div>
    </div>
    <div className="mt-12">
      {wallpapers.map((chunk, idx) => (
        <div key={`chunk_${idx}`} className="grid grid-cols-2 lg:flex gap-x-4 gap-y-10 px-12 mt-12">
          {chunk.map((entry, i) => (
            <div key={`chunk_${idx}_${i}`} style={{ flex: `${ratios[entry.name]}` }} className="relative border border-white flex justify-center items-center bg-black outline-2 outline-white hover:outline">
              <a href={entry.image} className="block" download={true}>
                <img src={entry.thumb} className="" />
              </a>
              <div className="absolute w-full text-white bottom-[-30px] text-center overflow-hidden overflow-ellipsis">{entry.name}</div>
            </div>
          ))}
        </div>
      ))}
    </div>
  </div>;
}


const WallPaperEntry = () => {
  return <div></div>;
}