import React, { useEffect, useState } from "react";
import { ghosts as rawGhosts } from "../../Data/Ghosts";


const familyLogos = {
  Ancient: require('../../Assets/Ghosts/families/Ancient.png').default,
  Dragon: require('../../Assets/Ghosts/families/Dragon.png').default,
  Flexible: require('../../Assets/Ghosts/families/Flexible.png').default,
  Fractal: require('../../Assets/Ghosts/families/Fractal.png').default,
  Molecular: require('../../Assets/Ghosts/families/Molecular.png').default,
  Viscous: require('../../Assets/Ghosts/families/Viscous.png').default,
  Spectral: require('../../Assets/Ghosts/families/Spectral.png').default,
  Strange: require('../../Assets/Ghosts/families/Strange.png').default,
};


const ghosts = rawGhosts.filter(g => g.name);

const GhostModal = ({ index }) => {
  const [idx, setIdx] = useState(index);
  const sheet = ghosts[idx];


  useEffect(() => {
    window.preloaded = [];
    return () => {
      delete window.preloaded;
    };
  }, []);

  useEffect(() => {
    // Preload next and previous cards
    preloadImage(ghosts[idx + 1 >= ghosts.length ? 0 : idx + 1].card);
    preloadImage(ghosts[idx - 1 < 0 ? ghosts.length - 1 : idx - 1].card);
    
    // Preload next and previous family icons
    preloadImage(familyLogos[ghosts[idx + 1 >= ghosts.length ? 0 : idx + 1].infos.family]);
    preloadImage(familyLogos[ghosts[idx - 1 < 0 ? ghosts.length - 1 : idx - 1].infos.family]);
  }, [idx]);

  const preloadImage = (url) => {
    console.log("Preloading", url);
    const img = new Image();
    img.onload = () => {
      window.preloaded[url] = img;
    }
    img.src = url; // by setting an src, you trigger browser download
  };


  const goNext = () => {
    setIdx(idx + 1 >= ghosts.length ? 0 : idx + 1);
    scrollToTop();
  }


  const goPrev = () => {
    setIdx(idx - 1 < 0 ? ghosts.length - 1 : idx - 1);
    scrollToTop();
  }

  const scrollToTop = () => {
    const el = document.getElementById('innerModal');
    if (el) {
      el.scrollTop = 0;
    }
  };


  return <div className="text-white font-normal grid grid-cols-[20%_60%_20%] grid-rows-[100vw] md:flex md:flex-row md:h-full md:items-center w-full md:gap-1">

    <div onClick={goPrev} className="col-start-1 row-start-1 row-span-2 md:w-[10%] hover:animate-pulse cursor-pointer"><img width="184" height="184" src={require(`../../Assets/Shared/Arrows/purple.png`).default} className="z-40 w-[100%] scale-x-[-1] cursor-pointer sticky top-[45%]" /></div>

    <div className="col-start-2 md:w-[30%] h-full flex flex-col justify-center relative pt-4 md:pt-0">
      <GhostCard sheet={sheet} />
    </div>
    <div className="col-start-2 flex-1 text-xs md:text-sm lg:text-base 2xl:text-base max-h-full py-6">
      <GhostInfos sheet={sheet} />
    </div>

    <div onClick={goNext} className="col-start-3 row-start-1 row-span-2 md:w-[10%] hover:animate-pulse cursor-pointer"><img width="184" height="184" src={require(`../../Assets/Shared/Arrows/purple.png`).default} className="z-40 w-[100%] cursor-pointer sticky top-[45%]" /></div>

  </div>
}


const GhostCard = ({ sheet }) => {
  return <div className="relative">
    <img src={sheet.card} className="mx-auto" />
    <div className="absolute top-[7%] h-[7.5%] left-[23%] right-[28%] uppercase text-[4vw] md:text-[1.8vw] flex items-center justify-center font-bold">
      {sheet.infos.family}
    </div>
    <div className="absolute top-[2.9%] h-[1.5%] left-[25%] right-[55%] uppercase text-[2.5vw] md:text-[1.2vw] flex items-center justify-center font-bold text-black">
      Level
    </div>

    <div style={{ textShadow: `0px 2px 6px #333` }} className="absolute am bottom-[17.6%] h-[7.5%] left-[28.8%] right-[3.8%] uppercase text-[7vw] md:text-[3.8vw] flex items-start justify-center tracking-wide">
      {sheet.name}
    </div>

    <div className="absolute bottom-[13.2%] h-[2.5%] left-[5%] right-[70%] uppercase text-[2.5vw] md:text-[1.2vw] flex items-center justify-center font-bold text-black">
      Type:
    </div>

    <div style={{ textShadow: `0px 2px 6px #333` }} className="absolute top-[88%] h-[5%] left-[12.2%] right-[14%] text-[3.2vw] md:text-[1.8vw] flex items-start justify-start font-bold tracking-wide leading-none">
      {sheet.infos.types}
    </div>
  </div>;
}

const GhostInfos = ({ sheet }) => {

  return <div className="relative md:pl-10">
    <img src={familyLogos[sheet.infos.family]} className="w-8 block md:absolute top-0 left-0" />
    FAMILY: {sheet.infos.family}< br />
    TYPE: {sheet.infos.types}< br />
    LEVEL: {sheet.infos.level}< br />
    NEST: {sheet.infos.nest}< br />
    RECEPTACLE: {sheet.infos.receptacle}< br />
    AGE: {sheet.infos.age}< br />
    RARITY: {sheet.infos.rarity}< br />
    <br />
    <div className="text-[color:#05ff1d] font-bold">ATTACKS</div>
    NUMBER: {sheet.infos.attacks.length} < br />
    {sheet.infos.attacks.map((a) => <React.Fragment key={`a_${a.number}_${a.name}`}><span className="font-bold">{a.number} – {a.name}:</span>{a.text}< br /></React.Fragment>)}
    <br />
    <div className="text-[color:#d800ff] font-bold">DEFENSES</div>
    NUMBER: {sheet.infos.defenses.length} < br />
    {sheet.infos.defenses.map((d) => <React.Fragment key={`d_${d.number}_${d.name}`}><span key={`d_${d.number}_${d.name}`} className="font-bold text-justify">{d.number} – {d.name}:</span> {d.text}< br /></React.Fragment>)}
    <br />
    <div className="text-[color:#00d8ff] font-bold">DEPLACEMENT</div> {sheet.infos.deplacement}< br />
    <br />
    <div className="text-[color:#ff8400] font-bold">WEAKNESS</div> {sheet.infos.Weakness}< br />
  </div>;
}

export default GhostModal;