import { useState } from "react";

const Videos = () => {
  const [showvideo, setShowvideo] = useState(false);
  return <section id="Videos" className="relative mt-[-7.6%] cursor-pointer z-20">


    <picture>
      <source srcSet={require(`../../Assets/Videos/background.webp`).default} type="image/webp" />
      <source srcSet={require(`../../Assets/Videos/background.png`).default} type="image/png" />
      <img alt="Click to play the trailer" width="1920" height="1332" loading="lazy" src={require("../../Assets/Videos/background.png").default} />
    </picture>

    {showvideo && <div className="fixed flex justify-center items-center inset-0 bg-[#000000aa] z-[100] translate-x-0" onClick={() => setShowvideo(false)}>
      <div className="flex justify-center z-40">
        <video autoPlay={true} controls={true} className="shadow-lg rounded w-full sm:w-[80%] h-full sm:h-[65%]" onClick={(e) => { e.stopPropagation() }}>
          <source src={require("../../Assets/Videos/trailer.mp4").default} />
        </video>
      </div>
    </div>}
    <h1 onClick={() => setShowvideo(true)} className="absolute top-0 z-10 flex flex-col w-full h-full items-center">
      <div className="h-[18%] flex justify-center items-center text-white text-[4vw] font-extrabold tracking-wider uppercase" style={{
        textShadow: `0px 2px 6px #333`,
      }}>
        Videos
      </div>
    </h1>
  </section>;
}



export default Videos;