import { ParallaxProvider } from "react-scroll-parallax";
import Detectoghost from "./Components/Detectoghost/Detectoghost";
import Footer from "./Components/Footer/Footer";
import Ghosts from "./Components/Ghosts/Ghosts";
import GhostSep, { GhostSep2, GhostSep3, GhostSep4 } from "./Components/Ghosts/Sep";
import Heroes from "./Components/Heroes/Heroes";
import Home from "./Components/Home/Home";
import Locations from "./Components/Locations/Locations";
import Menu from "./Components/Menu/Menu";
import Videos from "./Components/Videos/Videos";
function App() {
  // max-w-screen-xl
  return (
    <ParallaxProvider>
      <div className="App relative m-auto max-w-[1920px]">
        <Menu />
        <Home />
        <Heroes />
        <GhostSep />
        <Locations />
        <GhostSep3 />
        <Ghosts />
        <GhostSep2 />
        <Videos />
        <GhostSep4 />
        <Detectoghost />
        <Footer />
        <div id="modal" className="bg-black"></div>
      </div>
    </ParallaxProvider>
  );
}

export default App;
