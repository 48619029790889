import React, { useEffect, useState } from "react";


const heroes = [
  {
    name: "Mike", heroName: "Krush", glow: "#00d8ff", arrow: "blue", steps: [
      { image: require("../../Assets/Heroes/Mike/ID_MIKE.jpg").default, title: "MIKE" },
      { image: require("../../Assets/Heroes/Mike/ID_MIKE_Krusk.jpg").default, title: "KRUSH" },
      { image: require("../../Assets/Heroes/Mike/ID_MIKE_Gromax Kawai.jpg").default, title: "GROMAX Kawai" },
      { image: require("../../Assets/Heroes/Mike/ID_MIKE_Gromax Kaiju.jpg").default, title: "GROMAX Kaiju" },
    ],
    qualities: [
      { title: 'Intelligent', content: 'A science and technology buff, Mike is a fine strategist capable of developing a plan in the face of any situation.', },
      { title: 'Loyal', content: 'Mike is a friend you can count on from the moment you’ve managed to gain his trust.', },
      { title: 'Sensitive', content: 'Mike has a hard time hiding his emotions, which makes him touching.', },
      { title: 'Introvert', content: 'The world biggest basketball star’s only son, Mike is convinced - wrongly - that his father expects him to be good at sports... He suffers from a great lack of self- confidence, reinforced on a daily basis by his classmates, who only call him by his nickname «Junior»... Fortunately for him, the Bakers have arrived in New York: Liv and Andy are the only ones who call him by his real name.', },
      { title: 'Tender', content: 'Mike is a kind person and will always be kind to others. His friends know they can tell him anything: he will never judge them!', },
    ],
    faq: [
      { q: 'What does the Ghostforce brings to him', content: 'Being a part of the Ghostforce is Mike’s chance to grow into a family he’s chosen for himself. Thanks to Liv and Andy, Mike will never be alone again! Ms. Jones’ intelligence is a great source of inspiration for him too. Indeed, he admires the scientist above all.', },
      { q: 'Personal stakes in the Ghostforce', content: 'For Mike, becoming Krush represents an opportunity to become everything he’s not. He feels stronger, more useful and, in fact, he gains confidence and learns, little by little, to love himself as much as he loves others.', },
      { q: 'His role in the trio', content: 'He’s the brains of the outfit — the strategist, the one who comes up with plans of attack depending on the characteristics of the ghosts they’re fighting.', },
    ],

  },
  {
    name: "Andy", heroName: "Fury", glow: "#05ff1d", arrow: "green", steps: [
      { image: require("../../Assets/Heroes/Andy/ID_ANDY.jpg").default, title: "ANDY" },
      { image: require("../../Assets/Heroes/Andy/ID_ANDY_Fury.jpg").default, title: "FURY" },
      { image: require("../../Assets/Heroes/Andy/ID_ANDY_DRAGOYLE_Kawai.jpg").default, title: "DRAGOYLE Kawai" },
      { image: require("../../Assets/Heroes/Andy/ID_ANDY_DRAGOYLE_Kaiju.jpg").default, title: "DRAGOYLE Kaiju" },
    ],
    qualities: [
      { title: 'Funny', content: 'Of our three heroes, Andy is by far the one with the biggest sense of humor: as a Ghostforce or a civilian, he’s always ready with a joke... Especially when he’s afraid!', },
      { title: 'Spirited', content: 'He has a constant need to challenge himself and is constantly looking for that adrenaline rush.', },
      { title: 'Appealing', content: 'Andy likes to put himself forward, always in a cool, funny and sometimes ridiculous way. He tends to always exaggerate what he knows or can do... which often backfires!', },
      { title: 'Popular', content: 'Because he’s so cool and is such a great basketball player, Andy is among the most influential people at school.', },
      { title: 'Smart', content: 'As intuitive as he’s agile, Andy knows how to adapt to any situation. That’s what makes him so comfortable everywhere: he knows how to improvise and always bounces back with vivacity.', },
      { title: 'Confident', content: 'If there’s one thing Andy has, it’s confidence! In fact, it’s not uncommon for him to get into trouble because of a slight overestimation of his capacities...', },
    ],
    faq: [
      { q: 'What does the Ghostforce brings to him', content: 'A competitor at heart, being part of the Ghostforce is an inexhaustible source of adrenaline for Andy. In his eyes, every fight against a ghost is like a basketball match that has to be won.' },
      { q: 'Personal stakes in the Ghostforce', content: 'Being a member of the Ghostforce is also a way for Andy to grow and learn from his mistakes. Thanks to his experience in the field, our hero becomes a better person: he learns to be humble and to channel himself.', },
      { q: 'His role in the trio', content: 'He’s the go-getter of the band! He’ll always be ready to spring into action — even if it means taking reckless risks due to his overconfidence and his wild spirit!', },
    ],
  },
  {
    name: "Liv", heroName: "Myst", glow: "#ff00d8", arrow: "pink", steps: [
      { image: require("../../Assets/Heroes/Liv/ID_LIV.jpg").default, title: "LIV" },
      { image: require("../../Assets/Heroes/Liv/ID_LIV_Myst.jpg").default, title: "MYST" },
      { image: require("../../Assets/Heroes/Liv/ID_LIV_OCTOCAT Kawai.jpg").default, title: "OCTOCAT Kawai" },
      { image: require("../../Assets/Heroes/Liv/ID_LIV_OCTOCAT Kaiju.jpg").default, title: "OCTOCAT Kaiju" },
    ],
    qualities: [
      { title: 'Passionate', content: 'Liv was always fascinated by the Ghostforce — even before she became a member herself. She’s been intrigued by ghosts ever since she was a little girl, and she has tremendous admiration for Ms. Jones.', },
      { title: 'Frank', content: 'A naturally outspoken person, Liv always tells it like it is. A rare trait in someone her age, her directness sometimes causes her to unwittingly offend the people around her.', },
      { title: 'Fair', content: 'If there’s one thing Liv cannot abide, it’s injustice! There’s no length she won’t go to to introduce equity in situations that need it.', },
      { title: 'Uncompromising', content: 'Liv doesn’t play a part: she is true to herself and doesn’t play the superficial game. She doesn’t let others influence her and accepts herself the way she is!', },
      { title: 'Altruistic', content: 'Liv is without a doubt the one with the most empathy for other people. She can feel emotions better than anyone else... and sometimes get overwhelmed by them!', },
      { title: 'Optimistic', content: 'Liv never gives up and always finds a way of making the most of any situation.', },
    ],
    faq: [
      { q: 'What does the Ghostforce brings to her', content: 'For Liv, being a member of the Ghostforce is her wildest dream come true. It’s also her chance to assert herself, play to her strengths and prove to her big bro that he doesn’t need to protect her (Andy tends to worry a lot about his little sis).', },
      { q: 'Personal stakes in the Ghostforce', content: 'Liv thinks the lack of interest for Ms. Jones and Carpenter among the general population is unfair. If she aspires to be heroic on the field, it’s mostly because she wants to put the Ghostforce in the Pantheon of superheroes and ultimately, get Ms. Jones the recognition she deserves. And that ambition drives her to take risks, even if it means putting herself in danger!', },
      { q: 'Her role in the trio', content: 'She is the leader of the group. The one giving the directives, she is the pivotal point of our trio. She’s also the one who will rally the troops when everything seems lost!', },
    ],
  },
];

const HeroModal = ({ index }) => {
  const [idx, setIdx] = useState(index);
  const [imgIdx, setImgIdx] = useState(0);
  const sheet = heroes[idx];


  useEffect(() => {
    window.preloaded = [];
    return () => {
      delete window.preloaded;
    };
  }, []);

  useEffect(() => {
    // Preload next and previous images
    heroes[idx + 1 >= heroes.length ? 0 : idx + 1].steps.map(e => preloadImage(e.image));
    heroes[idx - 1 < 0 ? heroes.length - 1 : idx - 1].steps.map(e => preloadImage(e.image));
  }, [idx]);

  const preloadImage = (url) => {
    const img = new Image();
    img.onload = () => {
      window.preloaded[url] = img;
    }
    img.src = url; // by setting an src, you trigger browser download
  };


  useEffect(() => {
    const ivl = setInterval(() => {
      setImgIdx(oldValue => {
        const nextValue = oldValue + 1 >= sheet.steps.length ? 0 : oldValue + 1;
        return nextValue;
      });
    }, 2300);
    return () => clearInterval(ivl);
  }, [sheet]);

  const goNext = () => {
    setImgIdx(0);
    setIdx(idx + 1 >= heroes.length ? 0 : idx + 1);
    scrollToTop();
  }


  const goPrev = () => {
    setImgIdx(0);
    setIdx(idx - 1 < 0 ? heroes.length - 1 : idx - 1);
    scrollToTop();
  }

  const scrollToTop = () => {
    const el = document.getElementById('innerModal');
    if (el) {
      el.scrollTop = 0;
    }
  };


  return <div className="text-white font-normal grid grid-cols-[20%_60%_20%] grid-rows-[100vw] md:flex md:flex-row md:h-full md:items-center w-full gap-0">

    <div onClick={goPrev} className="col-start-1 row-start-1 row-span-2 md:w-[10%] hover:animate-pulse cursor-pointer"><img width="184" height="184" src={require(`../../Assets/Shared/Arrows/${sheet.arrow}.png`).default} className="z-40 w-[100%] scale-x-[-1] cursor-pointer sticky top-[45%]" /></div>

    <div className="col-start-2 md:w-[30%] md:h-full transition-opacity duration-300 md:flex md:flex-col justify-center" id="container-hero">
      <div className="relative flex md:items-center justify-center">
        {sheet.steps.map((entry, index) => (
          <div key={`hero_${index}`} className={`flex flex-col items-center justify-center absolute picture`}>
            <img src={entry.image} className={`max-h-[90%] mx-auto transition-opacity duration-500 ${index == imgIdx ? '' : 'opacity-0'}`} />
            <div className="flex justify-center font-extrabold tracking-wider uppercase text-base md:text-xl lg:text-2xl 2xl:text-4xl mt-[-8%] text-center z-40" style={{
              textShadow: `0px 0px 10px ${sheet.glow}`,
            }}>{sheet.steps[imgIdx].title}</div>
          </div>
        ))}
      </div>
    </div>
    <div className="col-start-2 text-xs focus-within:md:text-sm 2xl:text-base content-start py-4 flex-1 h-full">
      <div className="flex flex-col justify-start items-start">
        <div style={{ color: sheet.glow }} className="text-3xl">{sheet.name.toUpperCase()} is</div>
        {sheet.qualities.map((q, idx) => (
          <React.Fragment key={`q_${idx}`}>
            <div style={{ color: sheet.glow }} className="text-2xl">{q.title.toUpperCase()}</div>
            <div className="mb-6 text-lg text-justify leading-tight">{q.content}</div>
          </React.Fragment>
        ))}
        <div style={{ color: sheet.glow }} className="text-3xl">{sheet.name.toUpperCase()} is {sheet.heroName.toUpperCase()}</div>
        {sheet.faq.map((q, idx) => (
          <React.Fragment key={`faq_${idx}`}>
            <div className="text-lg font-bold">{q.q}:</div>
            <div className="text-lg text-justify mb-6 leading-tight">{q.content}</div>
          </React.Fragment>
        ))}
      </div>
    </div>
    <div onClick={goNext} className="col-start-3 row-start-1 row-span-2 md:w-[10%] hover:animate-pulse cursor-pointer"><img width="184" height="184" src={require(`../../Assets/Shared/Arrows/${sheet.arrow}.png`).default} className="z-40 w-[100%] cursor-pointer  sticky top-[45%]" /></div>
  </div>
}

export default HeroModal;