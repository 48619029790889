import { useState } from "react";
import { locations } from "../../Data/Locations";
import CharacterModal from "../Modal/CharacterModal";
import Modal from "../Modal/Modal";


const Locations = () => {
  const [index, setIndex] = useState(0);
  const [modalActive, setModalActive] = useState(null);

  const handleScroll = (change) => {
    let nextIndex = index + change;
    const el = document.getElementById('locations-scroll');
    if (nextIndex < 0) {
      nextIndex = locations.length - 1;
    }

    if (nextIndex > (locations.length - 1)) {
      nextIndex = 0;
    }

    el.scrollLeft = el.scrollWidth * locations[nextIndex].offset / 100;
    setIndex(nextIndex);
  };

  // const handleResize = () => {
  //   handleScroll(0);
  // }

  const closeModal = () => {
    setModalActive(null);
  }

  const openModal = (values) => {
    setModalActive(values);
  }

  return <section id="Characters">
    <div className="mt-[-4%] z-20 relative">
      <picture>
        <source srcSet={require('../../Assets/Locations/header.webp').default} type="image/webp" />
        <source srcSet={require('../../Assets/Locations/header.png').default} type="image/png" />
        <img alt="" width="1920" height="96" loading="lazy" src={require('../../Assets/Locations/header.png').default} />
      </picture>
    </div>
    <div className="relative pt-[1px] !bg-contain mt-[-1%]" style={{ background: `url(${require('../../Assets/Locations/bg.jpg').default}) no-repeat center top #101010` }}>

      <h1 className="mt-[2vw] flex justify-center items-center text-white text-[4vw] font-extrabold tracking-wider uppercase" style={{
        textShadow: `0px 2px 6px #333`,
      }}>
        Characters
      </h1>

      <div className="h-[72%] mt-[-8%] overflow-hidden flex flex-col md:flex-row scroll-smooth md:px-[10%] relative translate-y-[3%] md:translate-y-[15%] z-30" id="locations-scroll">
        {locations.map((e, idx) => (
          <LocationPage onClick={openModal} locIndex={idx} key={e.title} folder={e.folder} title={e.title} glow={e.glow} chars={e.chars} arrow={e.arrow} onChangeLocation={(direction) => handleScroll(direction)} />
        ))}
      </div>

    </div>
    <div className="h-0 relative z-10">
      <picture>
        <source srcSet={require('../../Assets/Locations/footer.webp').default} type="image/webp" />
        <source srcSet={require('../../Assets/Locations/footer.png').default} type="image/png" />
        <img alt="" width="1920" height="459" loading="lazy" src={require('../../Assets/Locations/footer.png').default} className="absolute top-0 mt-[-12%]" />
      </picture>
    </div>

    {modalActive !== null ? <Modal onClose={closeModal}>
      <CharacterModal index={modalActive.index} locationIndex={modalActive.locationIndex} />
    </Modal> : null}
  </section>;
}


const LocationPage = ({ folder, onClick, locIndex, onChangeLocation, glow, title, arrow }) => {

  return <div className="h-[100%] w-full flex-grow-0 flex-shrink-0 relative z-20">

    <picture>
      <source srcSet={require(`../../Assets/Locations/${folder}/background.webp`).default} type="image/webp" />
      <source srcSet={require(`../../Assets/Locations/${folder}/background.png`).default} type="image/png" />
      <img alt={title} loading="lazy" width="1634" height="996" src={require(`../../Assets/Locations/${folder}/background.png`).default} className="max-h-full cursor-pointer" onClick={() => onClick({ index: 0, locationIndex: locIndex })} />
    </picture>
    <div className="cursor-pointer absolute bottom-[4%] left-0 w-1/2 h-[12%] z-30" onClick={() => onChangeLocation(-1)}></div>
    <div className="cursor-pointer absolute bottom-[4%] right-0 w-1/2 h-[12%] z-30" onClick={() => onChangeLocation(1)}></div>
    <div className="absolute w-full bottom-[5.4%] md:bottom-[0.9%] flex justify-center items-center text-white text-[4vw] font-extrabold tracking-wider uppercase" style={{
      textShadow: `0px 0px 10px ${glow}, 0px 0px 10px ${glow}`,
    }}>
      <img alt="Back" loading="lazy" width="184" height="184" src={require(`../../Assets/Shared/Arrows/${arrow}.png`).default} className="hidden md:block z-40 w-[12%] scale-x-[-1] cursor-pointer" onClick={() => onChangeLocation(-1)} />
      {title}
      <img alt="Prev" loading="lazy" width="184" height="184" src={require(`../../Assets/Shared/Arrows/${arrow}.png`).default} className="hidden md:block z-40 w-[12%] cursor-pointer" onClick={() => onChangeLocation(1)} />
    </div>
  </div>
}

export default Locations;