import { useState } from "react";
import Socials from "../Footer/Socials";

const navElements = [
  {
    title: "Home",
    png: require("../../Assets/Shared/Nav/pink.png"),
    webp: require("../../Assets/Shared/Nav/pink.webp"),
    glow: "#ff00d8",
  },
  {
    title: "Heroes",
    png: require("../../Assets/Shared/Nav/green.png"),
    webp: require("../../Assets/Shared/Nav/green.webp"),
    glow: "#05ff1d",
  },
  {
    title: "Characters",
    png: require("../../Assets/Shared/Nav/blue.png"),
    webp: require("../../Assets/Shared/Nav/blue.webp"),
    glow: "#00d8ff",
  },
  {
    title: "Ghosts",
    png: require("../../Assets/Shared/Nav/purple.png"),
    webp: require("../../Assets/Shared/Nav/purple.webp"),
    glow: "#d800ff",
  },
  {
    title: "Videos",
    png: require("../../Assets/Shared/Nav/orange.png"),
    webp: require("../../Assets/Shared/Nav/orange.webp"),
    glow: "#ff8400",
  },
  {
    title: "Wallpapers",
    png: require("../../Assets/Shared/Nav/red.png"),
    webp: require("../../Assets/Shared/Nav/red.webp"),
    glow: "#ff0000 ",
  },
];

const Menu = () => {
  const [showMenu, setShowMenu] = useState(false);
  return (
    <>
      <div onClick={() => setShowMenu(!showMenu)} className={`block md:hidden cursor-pointer w-10 h-10 fixed z-[60] right-2 top-2 ${showMenu ? 'animate-bounce' : ''}`}>
        <picture>
          <source srcSet={require('../../Assets/Shared/Nav/burger.webp').default} type="image/webp" />
          <source srcSet={require('../../Assets/Shared/Nav/burger.png').default} type="image/png" />
          <img alt="Toggle navigation menu" src={require('../../Assets/Shared/Nav/burger.png').default} />
        </picture>
      </div>
      <nav id="menu" className={`fixed ${showMenu ? '' : 'translate-x-full'} md:h-[76px] ease-out transition-transform md:transition-none md:translate-x-0 left-0 right-0 bg-black z-50 bottom-0 top-0 md:bottom-auto`}>
        <ul className="flex flex-col md:flex-row overflow-x-auto md:overflow-hidden md:justify-around text-white font-extrabold text-2xl uppercase pt-4 mb-[-4px]">
          {navElements.map((e) => (
            <li key={e.title} className="md:text-center group pl-4 md:pl-0 h-[64px]">
              <a
                onClick={() => setShowMenu(false)}
                href={`#${e.title}`}
                className="relative block z-10 pr-[2px] h-[32px]"
                style={{
                  textShadow: `0px 0px 10px ${e.glow}, 0px 0px 10px ${e.glow}, 0px 0px 10px ${e.glow}`,
                }}
              >
                {e.title}
              </a>
              <picture>
                <source srcSet={e.webp.default} type="image/webp" />
                <source srcSet={e.png.default} type="image/png" />
                <img role="presentation" alt="" width="219" height="57" src={e.png.default} className="w-32 max-w-[8rem] inline-block mt-[-40px] ml-[-2rem] md:ml-[0px] md:mt-[-24px] group-hover:animate-pulse" />
              </picture>
            </li>
          ))}
        </ul>
        <div className="block md:hidden">
          <Socials />
        </div>
      </nav>
    </>
  );
};

export default Menu;
