import { useEffect, useState } from "react";
import { createPortal } from "react-dom";


const Modal = ({ children, onClose }) => {
  // useEffect(() => {
  //   let el = document.createElement('div');
  //   el.class = "bg-black fixed inset-0";
  //   modalRoot.appendChild(element);
  //   setElement(el);
  // }, []);

  const [container] = useState(() => {
    return document.createElement('div');
  });

  useEffect(() => {
    const modalRoot = document.getElementById('modal');

    while (modalRoot.firstChild) {
      modalRoot.removeChild(modalRoot.firstChild);
    }
    container.classList.add("modal")
    container.classList.remove("active");
    modalRoot.appendChild(container);
    // setTimeout(() => 
    document.body.classList.add("modal-open")
    // , 200);
    
    setTimeout(() => {
      container.classList.add("active");
    }, 20);
    return () => {
      setTimeout(() => document.body.classList.remove("modal-open"), 200);
      while (modalRoot.firstChild) {
        modalRoot.removeChild(modalRoot.firstChild);
      }
    }
  }, [])

  const closeModal = () => {
    const modalRoot = document.getElementById('modal');
    container.classList.remove("active");
    setTimeout(() => {
      while (modalRoot.firstChild) {
        modalRoot.removeChild(modalRoot.firstChild);
      }
      onClose();
    }, 200);




  }


  return createPortal(<div id="innerModal" className="h-full max-h-full overflow-y-auto"><ModalCloseButton onClose={closeModal} />{children}</div>, container);
}

const ModalCloseButton = ({ onClose }) => {
  return <div onClick={onClose} className="text-5xl absolute top-6 right-8 text-white font-bold cursor-pointer z-50" style={{
    textShadow: `0px 0px 10px #ff00d8, 0px 0px 10px #ff00d8, 0px 0px 10px #ff00d8`,
  }}>X</div>
}

export default Modal;
export { ModalCloseButton };

