export default function () {
  return <div className="grid grid-cols-4 md:flex md:gap-4 items-center justify-center w-full md:w-1/2 px-10">
    <a href="https://www.facebook.com/Ghostforce.official" target="_blank">
      <picture>
        <source srcSet={require("../../Assets/Shared/Social/insta.webp").default} type="image/webp" />
        <source srcSet={require("../../Assets/Shared/Social/insta.png").default} type="image/png" />
        <img alt="Instagram" width="126" height="104" loading="lazy" className="" src={require("../../Assets/Shared/Social/insta.png").default} />
      </picture>
    </a>
    <a href="https://www.instagram.com/ghostforce_official" target="_blank">
      <picture>
        <source srcSet={require("../../Assets/Shared/Social/facebook.webp").default} type="image/webp" />
        <source srcSet={require("../../Assets/Shared/Social/facebook.png").default} type="image/png" />
        <img alt="Facebook" width="126" height="104" loading="lazy" className="" src={require("../../Assets/Shared/Social/facebook.png").default} />
      </picture>
    </a>
    {/* <picture>
      <source srcSet={require("../../Assets/Shared/Social/twitter.webp").default} type="image/webp" />
      <source srcSet={require("../../Assets/Shared/Social/twitter.png").default} type="image/png" />
      <img alt="Twitter" width="126" height="104" loading="lazy" className="" src={require("../../Assets/Shared/Social/twitter.png").default} />
    </picture> */}
    {/* <picture>
      <source srcSet={require("../../Assets/Shared/Social/youtube.webp").default} type="image/webp" />
      <source srcSet={require("../../Assets/Shared/Social/youtube.png").default} type="image/png" />
      <img alt="Youtube" width="126" height="104" loading="lazy" className="" src={require("../../Assets/Shared/Social/youtube.png").default} />
    </picture> */}
  </div>;
}