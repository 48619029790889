import { useEffect, useState } from "react";
import { locations } from "../../Data/Locations";

const CharacterModal = ({ locationIndex, index }) => {
  const [idx, setIdx] = useState(index);
  const [locIdx, setLocIdx] = useState(locationIndex);
  const location = locations[locIdx];
  const sheet = location.chars[idx];

  useEffect(() => {
    window.preloaded = [];
    return () => {
      delete window.preloaded;
    };
  }, []);

  useEffect(() => {
    // Preload next and previous images
    // +1 
    const nextSheet = idx + 1 >= location.chars.length
      ? locations[locIdx + 1 >= locations.length ? 0 : locIdx + 1].chars[0]
      : location.chars[idx + 1]
    preloadImage(nextSheet.image);

    // -1
    const prevSheet = idx - 1 < 0
      ? locations[locIdx - 1 < 0 ? locations.length - 1 : locIdx - 1].chars[0]
      : location.chars[idx - 1]
    preloadImage(prevSheet.image);
    
  }, [idx]);

  const preloadImage = (url) => {
    const img = new Image();
    img.onload = () => {
      window.preloaded[url] = img;
    }
    img.src = url; // by setting an src, you trigger browser download
  };

  const goNext = () => {
    // Switch location
    if (idx + 1 >= location.chars.length) {
      return goNextLocation();
    }

    setIdx(idx + 1);
  }

  const goNextLocation = () => {
    setLocIdx(locIdx + 1 >= locations.length ? 0 : locIdx + 1);
    setIdx(0);
  }

  const goPrev = () => {
    if (idx - 1 < 0) {
      return goPrevLocation();
    }

    setIdx(idx - 1);
  }

  const goPrevLocation = () => {
    const prev = locIdx - 1 < 0 ? locations.length - 1 : locIdx - 1;
    setLocIdx(prev);
    setIdx(locations[prev].chars.length - 1);
  }

  return <div className="text-white font-normal grid grid-cols-[20%_60%_20%] grid-rows-[100vw] md:flex md:flex-row md:h-full md:items-center w-full md:gap-1">

    <div onClick={goPrev} className="col-start-1 row-start-1 row-span-2 md:w-[10%] hover:animate-pulse cursor-pointer"><img width="184" height="184" src={require(`../../Assets/Shared/Arrows/${location.arrow}.png`).default} className="z-40 w-[100%] scale-x-[-1] cursor-pointer sticky top-[45%]" /></div>

    <div className="col-start-2 md:w-[30%] h-full flex flex-col justify-center relative pt-4 md:pt-0">
      <img src={sheet.image} className="mx-auto max-h-[90%]" />

      <div className="flex justify-center text-center font-extrabold tracking-wider uppercase text-base md:text-lg lg:text-3xl 2xl:text-4xl  mt-[-5%]" style={{
        textShadow: `0px 0px 5px ${location.glow}, 0px 0px 8px ${location.glow}`,
      }}>{sheet.title}</div>
    </div>
    <div className="col-start-2 flex-1 text-sm md:text-base text-justify lg:text-base 2xl:text-base max-h-full py-6">
      {sheet.text}
    </div>

    <div onClick={goNext} className="col-start-3 row-start-1 row-span-2 md:w-[10%] hover:animate-pulse cursor-pointer"><img width="184" height="184" src={require(`../../Assets/Shared/Arrows/${location.arrow}.png`).default} className="z-40 w-[100%] cursor-pointer sticky top-[45%]" /></div>

  </div>
}

export default CharacterModal;